import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getSortedProfileAddresses,
  getValidatedProfileData,
} from 'store/electronicRegistrationProcess/selectors/profile';
import { getFieldsConfiguration } from 'store/settings/selectors';
import { mapAddressData } from 'utils/regCardPresentationDataMappers/mapAddressData';

import { ProfilePresentationSectionData } from '../types';

interface Props {
  showOnlyPrimary?: boolean;
}

export const useAddressesConfiguration = ({
  showOnlyPrimary,
}: Props): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const addresses = useSelector(getSortedProfileAddresses);

  const addressFieldsConfiguration =
    fieldsConfiguration?.addressesObjectCollection;

  const addressesData = useMemo(
    () =>
      mapAddressData(addresses, {
        fieldConfiguration: addressFieldsConfiguration,
        showOnlyPrimary,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showOnlyPrimary, addressFieldsConfiguration, addresses, i18n.language]
  );

  return useMemo(
    () => ({
      data: addressesData,
      isVisible:
        fieldsConfiguration?.addressesObjectCollection?.isVisible ?? true,
      isMissingRequiredInformation: validatedProfileData
        ? !validatedProfileData.addresses
        : false,
    }),
    [addressesData, fieldsConfiguration, validatedProfileData]
  );
};
