import { KioskPreferredLanguageDetails } from 'api/KioskApi/entries';
import {
  FieldConfiguration,
  GuestAlternateNameSection,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

const ALTERNATE_DETAILS_CONFIGURATION_MAP: Record<
  keyof GuestAlternateNameSection,
  keyof KioskPreferredLanguageDetails | undefined
> = {
  firstNameField: 'firstname',
  languageField: 'language',
  lastNameField: 'lastname',
  isEditable: undefined,
  isRequired: undefined,
  isVisible: undefined,
};

export const validateAlternateDetails = (
  alternateDetailsConfiguration: GuestAlternateNameSection,
  alternateDetails?: KioskPreferredLanguageDetails
): boolean => {
  if (!alternateDetails) return true;

  return Object.entries(alternateDetailsConfiguration).every(
    ([key, fieldConfiguration]: [
      keyof GuestAlternateNameSection,
      FieldConfiguration
    ]): boolean => {
      const profileRecordKey = ALTERNATE_DETAILS_CONFIGURATION_MAP[key];

      if (profileRecordKey && fieldConfiguration?.isRequired) {
        return !!alternateDetails[profileRecordKey];
      }

      return true;
    }
  );
};
