import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';

import {
  getProfile,
  getProfileSecondSurname,
} from 'store/electronicRegistrationProcess/selectors/profile';
import { DateManager } from 'utils';
import {
  PersonalDetailsFormProperties,
  PersonalDetailsFormValues,
} from 'views/RegistrationCardEditPersonal/types';

import { useAlternateDetailsInitialValues } from './useAlternateDetailsInitialValues';

interface PersonalDetailsInitialValuesResult {
  initialValues: PersonalDetailsFormValues;
}

export const usePersonalDetailsInitialValues = (): PersonalDetailsInitialValuesResult => {
  const profile = useSelector(getProfile);
  const secondSurname = useSelector(getProfileSecondSurname);
  const {
    initialValues: alternateDetailsInitialValues,
  } = useAlternateDetailsInitialValues();
  const personalDetails = profile?.personalDetails;

  const initialValues = useMemo(() => {
    const birthday =
      personalDetails?.birthday &&
      DateManager.getFormattedDateWithConditionalYear(
        {
          ...personalDetails.birthday,
          month: personalDetails.birthday.month - 1,
        },
        DATE_PICKER_DATE_FORMAT
      );

    return {
      [PersonalDetailsFormProperties.title]: personalDetails?.title?.id,
      [PersonalDetailsFormProperties.lastName]: personalDetails?.lastName,
      [PersonalDetailsFormProperties.firstName]: personalDetails?.firstName,
      [PersonalDetailsFormProperties.middleName]: personalDetails?.middleName,
      [PersonalDetailsFormProperties.secondSurname]: secondSurname,
      [PersonalDetailsFormProperties.suffix]: personalDetails?.suffix?.id,
      [PersonalDetailsFormProperties.nationality]:
        personalDetails?.nationality?.code,
      [PersonalDetailsFormProperties.birthday]: birthday,
      [PersonalDetailsFormProperties.alternateDetails]: alternateDetailsInitialValues,
    };
  }, [personalDetails, secondSurname, alternateDetailsInitialValues]);

  return {
    initialValues,
  };
};
