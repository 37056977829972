import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Action } from '@ac/library-utils/dist/declarations';

import { ElectronicRegistrationProcessApi } from 'api/KioskApi';
import { KIOSK_SESSION_ID_HEADER } from 'configs/constants';
import { fetchRegistrationCardDetails } from 'store/electronicRegistrationProcess/actions';
import { getElectronicRegistrationProcessId } from 'store/electronicRegistrationProcess/selectors';
import { handleSagaError } from 'utils/sagas';

import { SagasGenerator } from 'types/shared';

import { UploadProfileActionPayload } from './interfaces/actionPayloads';
import * as actions from './actions';

export function* handleUpdateProfile(
  action: Action<UploadProfileActionPayload>
): SagasGenerator {
  try {
    const kioskSessionId: string = yield select(
      getElectronicRegistrationProcessId
    );

    yield ElectronicRegistrationProcessApi.updateProfile({
      data: action.payload.data,
      customConfig: {
        headers: {
          [KIOSK_SESSION_ID_HEADER]: kioskSessionId,
        },
      },
    });

    yield put(fetchRegistrationCardDetails.trigger(kioskSessionId));
    yield put(actions.updateProfile.success());

    if (action.payload.onSuccessfulUpdate) {
      yield call(action.payload.onSuccessfulUpdate);
    }
  } catch (error) {
    yield put(actions.updateProfile.failure(handleSagaError(error)));
  }
}

export function* registrationCardEditPersonalViewSagas(): SagasGenerator {
  yield takeLatest(actions.updateProfile.trigger, handleUpdateProfile);
}
