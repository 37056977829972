import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Separator } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { COMMUNICATION_MODES } from 'configs';
import {
  getCommunicationModeOrder,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { useSubForm } from 'utils/form/subFormApi';

import { EmailsFormValues, FormProperties } from '../../types';
import { SubFormSectionWrapper } from '../SubFormSectionWrapper/SubFormSectionWrapper';

import { EmailsSubForm } from './components/EmailsSubForm/EmailsSubForm';
import { MobilesSubForm } from './components/MobilesSubForm/MobilesSubForm';
import { PhonesSubForm } from './components/PhonesSubForm/PhonesSubForm';

const TEST_SUFFIXES = {
  subFormSection: '-sub-form-section',
  emailsSection: '-emails-section',
  phonesSection: '-phones-section',
  mobilesSection: '-mobiles-section',
};

interface Props {
  dataTestSelector?: string;
  isExpanded?: boolean;
}

export const ContactSubForm = ({
  dataTestSelector,
  isExpanded,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const communicationModeOrder = useSelector(getCommunicationModeOrder);

  const emailSubFormState = useSubForm<EmailsFormValues>(
    [FormProperties.emails],
    {
      valid: true,
    }
  );
  const mobileSubFormState = useSubForm<EmailsFormValues>(
    [FormProperties.mobiles],
    {
      valid: true,
    }
  );
  const phoneSubFormState = useSubForm<EmailsFormValues>(
    [FormProperties.phones],
    {
      valid: true,
    }
  );

  const subForms = useMemo(() => {
    const isMobilesSectionVisible =
      fieldsConfiguration?.mobileObjectCollection?.isEditable;
    const isPhonesSectionVisible =
      fieldsConfiguration?.phoneObjectCollection?.isEditable;
    const isEmailsSectionVisible =
      fieldsConfiguration?.emailObjectCollection?.isEditable;

    const emailSequence = communicationModeOrder.indexOf(
      COMMUNICATION_MODES.email
    );
    const mobileSequence = communicationModeOrder.indexOf(
      COMMUNICATION_MODES.mobile
    );
    const phoneSequence = communicationModeOrder.indexOf(
      COMMUNICATION_MODES.phone
    );

    const sectionsEntries = Object.entries({
      [emailSequence]: isEmailsSectionVisible
        ? {
            SubFormSection: EmailsSubForm,
            selector: dataTestSelector?.concat(TEST_SUFFIXES.emailsSection),
          }
        : undefined,
      [phoneSequence]: isPhonesSectionVisible
        ? {
            SubFormSection: PhonesSubForm,
            selector: dataTestSelector?.concat(TEST_SUFFIXES.phonesSection),
          }
        : undefined,
      [mobileSequence]: isMobilesSectionVisible
        ? {
            SubFormSection: MobilesSubForm,
            selector: dataTestSelector?.concat(TEST_SUFFIXES.mobilesSection),
          }
        : undefined,
    });

    return sectionsEntries
      .sort(([a], [b]) => Number(a) - Number(b))
      .map(([, component]) => component)
      .filter(isDefined);
  }, [fieldsConfiguration, dataTestSelector, communicationModeOrder]);

  const isValid = useMemo(() => {
    return (
      emailSubFormState.valid &&
      mobileSubFormState.valid &&
      phoneSubFormState.valid
    );
  }, [emailSubFormState, mobileSubFormState, phoneSubFormState]);

  return (
    <SubFormSectionWrapper
      dataTestSelector={dataTestSelector}
      title={t('REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.TITLE')}
      isValid={isValid}
      isExpanded={isExpanded}
    >
      <Box>
        {subForms.map(({ SubFormSection, selector }, index, collection) => {
          const isLastElement = index + 1 === collection.length;

          return (
            <Box
              dataTestSelector={dataTestSelector?.concat(
                TEST_SUFFIXES.subFormSection
              )}
              key={index}
            >
              <SubFormSection dataTestSelector={selector} />
              {!isLastElement && <Separator className="spacing-vertical-xlg" />}
            </Box>
          );
        })}
      </Box>
    </SubFormSectionWrapper>
  );
};

ContactSubForm.testSuffixes = TEST_SUFFIXES;
