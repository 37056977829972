import { MobileSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import {
  ConfigurationFieldsMap,
  SubFormFieldsConfiguration,
  useSubFormFieldsConfiguration,
} from 'views/RegistrationCardEditPersonal/hooks/useSubFormFieldsConfiguration';

import {
  FormProperties,
  FormValues,
  PhoneFormProperties,
  PhonesFormValues,
} from '../../../../../types';

const CONFIGURATION_TO_FORM_FIELD_MAP: ConfigurationFieldsMap<
  keyof MobileSection,
  PhonesFormValues
> = {
  detailsField: PhoneFormProperties.phoneNumber,
  typeField: PhoneFormProperties.type,
};

type MobilesFieldsConfigurationResult = SubFormFieldsConfiguration<
  FormValues,
  PhonesFormValues,
  [FormProperties.mobiles, number]
>;

export const useMobilesFieldsConfiguration = (
  path: [FormProperties.mobiles, number]
): MobilesFieldsConfigurationResult => {
  const getMobilesSubFormConfiguration = useSubFormFieldsConfiguration<
    FormValues,
    PhonesFormValues,
    [FormProperties.mobiles, number]
  >('mobileObjectCollection', path);

  return getMobilesSubFormConfiguration(CONFIGURATION_TO_FORM_FIELD_MAP);
};
