import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getValidatedProfileData,
  getValidProfileDocuments,
} from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getDateTimeFormats,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { mapDocumentsData } from 'utils/regCardPresentationDataMappers/mapDocumentsData';

import { ProfilePresentationSectionData } from '../types';

export const useDocumentsConfiguration = (): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const identityDocuments = useSelector(getValidProfileDocuments);

  const documentsData = useMemo(
    () =>
      mapDocumentsData(identityDocuments, {
        fieldsConfiguration:
          fieldsConfiguration?.identityDocumentObjectCollection,
        shortDateFormat: dateTimeFormats?.shortDateFormat,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [identityDocuments, i18n.language, dateTimeFormats, fieldsConfiguration]
  );

  return useMemo(
    () => ({
      data: documentsData,
      isVisible:
        fieldsConfiguration?.identityDocumentObjectCollection?.isVisible ??
        true,
      isMissingRequiredInformation: validatedProfileData
        ? !validatedProfileData.documents
        : false,
    }),
    [documentsData, fieldsConfiguration, validatedProfileData]
  );
};
