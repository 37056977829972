import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlignItems,
  Button,
  ButtonPattern,
  Color,
  ExpandPanel,
  Flex,
  Icon,
  IconTypes,
  Separator,
  Size,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import { ProfilePresentationDataElement } from 'utils/regCardPresentationDataMappers';

import { PersonalSubSection } from '../PersonalSubSection/PersonalSubSection';

interface PersonalNestedSectionProps {
  title: string;
  noDataInfo?: string;
  isMissingRequiredInformation?: boolean;
  data?: ProfilePresentationDataElement[];
  className?: string;
  dataTestSelector?: string;
  withSeparators?: boolean;
  enableCollapsible?: boolean;
}

export const PersonalNestedSection = ({
  data = [],
  className,
  title,
  noDataInfo,
  isMissingRequiredInformation,
  dataTestSelector,
  withSeparators,
  enableCollapsible,
}: PersonalNestedSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const [isSectionExpanded, setSectionExpanded] = useState(false);

  const sectionToggle = useCallback(() => {
    return setSectionExpanded((prevValue) => !prevValue);
  }, []);

  const subSectionTypes = useMemo(() => {
    return {
      nonCollapsableSections: data.filter((item) => !item.isCollapsible),
      collapsibleSections: data.filter((item) => item.isCollapsible),
    };
  }, [data]);

  return (
    <div data-test-selector={dataTestSelector} className={className}>
      <Text
        size={TextSize.lg}
        className="spacing-top-sm"
        dataTestSelector={dataTestSelector?.concat('-title')}
      >
        {title}
      </Text>

      {isMissingRequiredInformation && (
        <Flex
          className="gap-sm spacing-bottom-sm spacing-top-lg"
          alignItems={AlignItems.center}
          dataTestSelector={dataTestSelector?.concat(
            '-missing-required-warning'
          )}
        >
          <Icon type={IconTypes.warning} size={Size.sm} color={Color.warning} />
          <Text>{t('VALIDATION.MISSING_REQUIRED_INFO')}</Text>
        </Flex>
      )}
      {data?.length ? (
        <ExpandPanel
          dataTestSelector={dataTestSelector?.concat('-data-container')}
          isExpanded={!enableCollapsible || isSectionExpanded}
        >
          {subSectionTypes.nonCollapsableSections.map(
            ({ header, sectionData }, index) => (
              <Fragment key={`${index}-${header}`}>
                {withSeparators && index > 0 && (
                  <Separator className="spacing-top-xlg spacing-bottom-xlg" />
                )}
                <PersonalSubSection
                  title={header}
                  data={sectionData}
                  className="spacing-top-sm"
                  dataTestSelector={dataTestSelector?.concat(
                    '-data-non-collapsible-subsection'
                  )}
                />
              </Fragment>
            )
          )}

          {!!subSectionTypes?.collapsibleSections.length && (
            <>
              {enableCollapsible && (
                <Button
                  className="padding-sm spacing-top-sm"
                  pattern={ButtonPattern.tertiary}
                  icon={
                    isSectionExpanded ? IconTypes.collapse : IconTypes.expand
                  }
                  onClick={sectionToggle}
                  dataTestSelector={dataTestSelector?.concat('-expand-button')}
                >
                  {isSectionExpanded
                    ? t('COMPONENTS.PERSONAL_DETAILS_SECTION.BUTTONS.SHOW_LESS')
                    : t(
                        'COMPONENTS.PERSONAL_DETAILS_SECTION.BUTTONS.SHOW_MORE'
                      )}
                </Button>
              )}

              {subSectionTypes.collapsibleSections.map(
                ({ header, sectionData }, index) => (
                  <ExpandPanel.ExtendableElement key={`${header}-${index}`}>
                    {withSeparators && (
                      <Separator className="spacing-top-xlg spacing-bottom-xlg" />
                    )}
                    <PersonalSubSection
                      title={header}
                      data={sectionData}
                      className="spacing-top-sm"
                      dataTestSelector={dataTestSelector?.concat(
                        '-data-collapsible-subsection'
                      )}
                    />
                  </ExpandPanel.ExtendableElement>
                )
              )}
            </>
          )}
        </ExpandPanel>
      ) : (
        <Text
          weight={TextWeight.light}
          className="spacing-top-sm"
          dataTestSelector={dataTestSelector?.concat('-no-data-provided')}
        >
          {noDataInfo}
        </Text>
      )}
    </div>
  );
};
