import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getRegistrationCardDetails } from 'store/electronicRegistrationProcess/selectors';
import { getValidatedProfileData } from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getDateTimeFormats,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { mapPersonalData } from 'utils/regCardPresentationDataMappers/mapPersonalData';

import { ProfilePresentationSectionData } from '../types';

export const usePersonalDetailsConfiguration = (): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const cardDetails = useSelector(getRegistrationCardDetails);

  const personalInfo = useMemo(
    () =>
      mapPersonalData(cardDetails, {
        fieldsConfiguration,
        shortDateFormat: dateTimeFormats?.shortDateFormat,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cardDetails,
      fieldsConfiguration,
      dateTimeFormats?.shortDateFormat,
      i18n.language,
    ]
  );

  return useMemo(
    () => ({
      data: personalInfo,
      isVisible:
        fieldsConfiguration?.guestPersonalDetailsObject?.isVisible ?? true,
      isMissingRequiredInformation: validatedProfileData
        ? !validatedProfileData.personalDetails ||
          !validatedProfileData.alternateDetails
        : false,
    }),
    [personalInfo, fieldsConfiguration, validatedProfileData]
  );
};
