import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalResultType,
  PromiseResolvePayload,
  RequiredSelectionModalOption,
  usePromiseConfirmModal,
  usePromiseRequiredSelectionModal,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import { NestedFieldArrayRenderProps } from '@ac/react-infrastructure';

import { SubFormState } from 'utils/form/subFormApi';
import { toCapitalize } from 'utils/toCapitalize';

import { BaseObject } from 'types/shared';

import { useAddressFormatter } from '../components/AddressSubForm/hooks/useAddressFormatter';
import { useEmailFormatter } from '../components/ContactSubForm/components/EmailsSubForm/hooks/useEmailFormatter';
import { useMobileFormatter } from '../components/ContactSubForm/components/MobilesSubForm/hooks/useMobileFormatter';
import { usePhoneFormatter } from '../components/ContactSubForm/components/PhonesSubForm/hooks/usePhoneFormatter';
import { useDocumentFormatter } from '../components/DocumentsSubForm/hooks/useDocumentFormatter';

export enum EntityType {
  Address = 'Address',
  Mobiles = 'Mobiles',
  Phones = 'Phones',
  Emails = 'Emails',
  Document = 'Document',
}

type Entity = { isPrimary?: boolean } | BaseObject;

type RemoveEntityResult = (
  state: SubFormState<Entity[]>,
  fieldFieldArrayRenderProps: NestedFieldArrayRenderProps<
    Record<string, Entity[] | undefined>,
    string
  >,
  entityIndex: number
) => Promise<void>;

export const useRemoveEntity = (type: EntityType): RemoveEntityResult => {
  const { t } = useTranslation();

  const openConfirmModal = usePromiseConfirmModal();
  const openRequiredSelectionModal = usePromiseRequiredSelectionModal();

  const formatAddress = useAddressFormatter();
  const formatPhone = usePhoneFormatter();
  const formatMobile = useMobileFormatter();
  const formatEmail = useEmailFormatter();
  const formatDocument = useDocumentFormatter();

  const configuration = useMemo(() => {
    switch (type) {
      case EntityType.Address:
        return {
          entityTranslatedName: t(
            'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.ENTITY_NAME'
          ),
          entityFormatter: formatAddress,
        };
      case EntityType.Emails:
        return {
          entityTranslatedName: t(
            'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.EMAIL.ENTITY_NAME'
          ),
          entityFormatter: formatEmail,
        };
      case EntityType.Mobiles:
        return {
          entityTranslatedName: t(
            'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.MOBILE.ENTITY_NAME'
          ),
          entityFormatter: formatMobile,
        };
      case EntityType.Phones:
        return {
          entityTranslatedName: t(
            'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.PHONE.ENTITY_NAME'
          ),
          entityFormatter: formatPhone,
        };
      case EntityType.Document:
        return {
          entityTranslatedName: t(
            'REGISTRATION_CARD_EDIT_PERSONAL.DOCUMENT.ENTITY_NAME'
          ),
          entityFormatter: formatDocument,
        };
      default:
        return undefined;
    }
  }, [
    type,
    t,
    formatAddress,
    formatEmail,
    formatMobile,
    formatPhone,
    formatDocument,
  ]);

  const getReplaceOptions = useCallback(
    (
      entities: BaseObject[],
      entityIndex: number
    ): RequiredSelectionModalOption[] => {
      const entityName = configuration?.entityTranslatedName || '';

      return entities
        .map((entity, index): RequiredSelectionModalOption | undefined =>
          index !== entityIndex
            ? {
                value: String(index),
                title: {
                  label: t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.COMPONENTS.REMOVE_ENTITY_MODAL.REPLACEMENT_LABEL',
                    {
                      entity: toCapitalize(entityName),
                      counter: index + 1,
                    }
                  ),
                  content: configuration?.entityFormatter(entity) ?? '',
                },
              }
            : undefined
        )
        .filter(isDefined);
    },
    [configuration, t]
  );

  const openRemoveModalWithSelection = useCallback(
    (
      replaceOptions: RequiredSelectionModalOption[]
    ): Promise<PromiseResolvePayload<string, ModalResultType> | undefined> => {
      return openRequiredSelectionModal({
        header: t(
          'REGISTRATION_CARD_EDIT_PERSONAL.COMPONENTS.REMOVE_ENTITY_MODAL.REPLACEMENT_HEADER',
          {
            entity: configuration?.entityTranslatedName,
          }
        ),
        description: t(
          'REGISTRATION_CARD_EDIT_PERSONAL.COMPONENTS.REMOVE_ENTITY_MODAL.REPLACEMENT_HINT',
          {
            entity: configuration?.entityTranslatedName,
          }
        ),
        dataTestSelector: `remove-${type}-entity-with-selection-confirm-modal`,
        selectionOptions: replaceOptions,
      });
    },
    [configuration, openRequiredSelectionModal, t, type]
  );

  const openRemoveModal = useCallback(
    (
      entityValues: BaseObject
    ): Promise<PromiseResolvePayload<never, ModalResultType> | undefined> => {
      return openConfirmModal({
        header: t(
          'REGISTRATION_CARD_EDIT_PERSONAL.COMPONENTS.REMOVE_ENTITY_MODAL.BASIC_HEADER',
          {
            entity: configuration?.entityTranslatedName,
          }
        ),
        dataTestSelector: `remove-${type}-entity-confirm-modal`,
        description: configuration?.entityFormatter(entityValues),
      });
    },
    [configuration, openConfirmModal, t, type]
  );

  return useCallback(
    async (state, fieldFieldArrayRenderProps, entityIndex): Promise<void> => {
      const entities = state.values || [];
      const entityValues = entities[entityIndex];

      const replaceOptions = getReplaceOptions(entities, entityIndex);
      const isReplacementSelectionPossible =
        entityValues &&
        'isPrimary' in entityValues &&
        (entityValues as { isPrimary: boolean }).isPrimary &&
        !!replaceOptions.length;

      const modalResult = isReplacementSelectionPossible
        ? await openRemoveModalWithSelection(replaceOptions)
        : await openRemoveModal(entityValues);

      if (modalResult?.resultType === ModalResultType.Confirmed) {
        if (isDefined(modalResult.output)) {
          const newPrimaryIndex = Number(modalResult.output);

          fieldFieldArrayRenderProps.fields.update(newPrimaryIndex, {
            ...entities[newPrimaryIndex],
            isPrimary: true,
          });
        }

        fieldFieldArrayRenderProps.fields.remove(entityIndex);
      }
    },
    [getReplaceOptions, openRemoveModal, openRemoveModalWithSelection]
  );
};
