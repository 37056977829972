import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  Section,
  SectionType,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { getCustomMessages } from 'store/settings/selectors';

import { useAddressesConfiguration } from './hooks/useAddressesConfiguration';
import { useContactsConfiguration } from './hooks/useContactsConfiguration';
import { useDocumentsConfiguration } from './hooks/useDocumentsConfiguration';
import { usePersonalDetailsConfiguration } from './hooks/usePersonalDetailsConfiguration';
import { PersonalNestedSection } from './PersonalNestedSection/PersonalNestedSection';

import './PersonalDetailsSection.scss';

interface PersonalDetailsSectionProps {
  editButtonVisible?: boolean;
  enableCollapsible?: boolean;
  showOnlyPrimary?: boolean;
  dataTestSelector?: string;
  onEditButtonClick?: () => void;
}

export const PersonalDetailsSection = ({
  editButtonVisible,
  enableCollapsible,
  showOnlyPrimary,
  dataTestSelector,
  onEditButtonClick,
}: PersonalDetailsSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const customMessages = useSelector(getCustomMessages);

  const profileDetailsConfiguration = usePersonalDetailsConfiguration();
  const addressesConfiguration = useAddressesConfiguration({ showOnlyPrimary });
  const contactsConfiguration = useContactsConfiguration({ showOnlyPrimary });
  const documentsConfiguration = useDocumentsConfiguration();

  return (
    <Section
      type={SectionType.wide}
      className="reg-card-personal-section"
      dataTestSelector={dataTestSelector}
    >
      {customMessages?.GREETINGS_MESSAGE && (
        <Text
          size={TextSize.xlg}
          dataTestSelector={dataTestSelector?.concat('-greetings-message')}
          className="spacing-bottom-lg"
        >
          {customMessages?.GREETINGS_MESSAGE}
        </Text>
      )}
      <div className="reg-card-personal-section-wrapper">
        {profileDetailsConfiguration.isVisible && (
          <PersonalNestedSection
            title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.PERSONAL')}
            className="reg-card-standard-section"
            data={profileDetailsConfiguration.data}
            dataTestSelector={dataTestSelector?.concat(
              '-profile-details-section'
            )}
            isMissingRequiredInformation={
              profileDetailsConfiguration.isMissingRequiredInformation
            }
            enableCollapsible={enableCollapsible}
          />
        )}

        {addressesConfiguration.isVisible && (
          <PersonalNestedSection
            dataTestSelector={dataTestSelector?.concat(
              '-address-details-section'
            )}
            isMissingRequiredInformation={
              addressesConfiguration.isMissingRequiredInformation
            }
            title={t('SHARED.ADDRESS')}
            noDataInfo={t('SHARED.NO_ADDRESS')}
            className="reg-card-standard-section reg-card-address-section"
            data={addressesConfiguration.data}
            withSeparators
            enableCollapsible={enableCollapsible}
          />
        )}

        {contactsConfiguration.isVisible && (
          <PersonalNestedSection
            dataTestSelector={dataTestSelector?.concat(
              '-contact-details-section'
            )}
            title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.CONTACT')}
            noDataInfo={t('SHARED.NO_CONTACT')}
            className="reg-card-wrappable-section"
            data={contactsConfiguration.data}
            isMissingRequiredInformation={
              contactsConfiguration.isMissingRequiredInformation
            }
            enableCollapsible={enableCollapsible}
          />
        )}

        {documentsConfiguration.isVisible && (
          <PersonalNestedSection
            dataTestSelector={dataTestSelector?.concat(
              '-document-details-section'
            )}
            title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.ID_AND_DOCUMENTS')}
            noDataInfo={t('SHARED.NO_DOCUMENT')}
            className="reg-card-wrappable-section"
            data={documentsConfiguration.data}
            isMissingRequiredInformation={
              documentsConfiguration.isMissingRequiredInformation
            }
            withSeparators
            enableCollapsible={enableCollapsible}
          />
        )}
      </div>
      {editButtonVisible && (
        <Button
          dataTestSelector={dataTestSelector?.concat('-edit-button')}
          className="reg-card-personal-edit-button"
          pattern={ButtonPattern.secondary}
          onClick={onEditButtonClick}
        >
          {t('SHARED.EDIT')}
        </Button>
      )}
    </Section>
  );
};
