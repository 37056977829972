import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskAddress } from 'api/KioskApi/entries';
import { getSortedProfileAddresses } from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getDefaultAddressType,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import {
  AddressFormProperties,
  AddressFormValues,
} from 'views/RegistrationCardEditPersonal/types';

interface AddressesInitialValuesResult {
  prepareInitialEmptyAddress: (isPrimary: boolean) => AddressFormValues;
  initialValues: AddressFormValues[] | undefined;
}

export const useAddressesInitialValues = (): AddressesInitialValuesResult => {
  const profileAddresses = useSelector(getSortedProfileAddresses);
  const defaultAddressType = useSelector(getDefaultAddressType);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);

  const addressConfiguration = fieldsConfiguration?.addressesObjectCollection;

  const prepareInitialEmptyAddress = useCallback(
    (isPrimary: boolean): AddressFormValues => {
      return {
        [AddressFormProperties.languageCode]: i18next.language.toUpperCase(),
        [AddressFormProperties.type]: defaultAddressType?.id,
        [AddressFormProperties.isPrimary]: isPrimary,
      };
    },
    [defaultAddressType]
  );

  const prepareSingleAddressInitialValues = useCallback(
    (address?: Partial<KioskAddress>): AddressFormValues | undefined => {
      return {
        [AddressFormProperties.id]: address?.id,
        [AddressFormProperties.languageCode]:
          address?.language?.code || i18next.language.toUpperCase(),
        [AddressFormProperties.isPrimary]: address?.isPrimary || false,
        [AddressFormProperties.addressLine1]: address?.addressLine1,
        [AddressFormProperties.addressLine2]: address?.addressLine2,
        [AddressFormProperties.city]: address?.city,
        [AddressFormProperties.country]: address?.country?.code,
        [AddressFormProperties.postalCode]: address?.postalCode,
        [AddressFormProperties.state]: address?.state?.code,
        [AddressFormProperties.type]: address?.type?.id,
        [AddressFormProperties.district]: address?.district?.id,
      } as AddressFormValues;
    },
    []
  );

  const initialValues = useMemo(() => {
    if (!profileAddresses?.length && addressConfiguration?.isRequired) {
      return [prepareInitialEmptyAddress(true)];
    }

    return profileAddresses
      ?.map(prepareSingleAddressInitialValues)
      .filter(isDefined);
  }, [
    addressConfiguration,
    prepareInitialEmptyAddress,
    prepareSingleAddressInitialValues,
    profileAddresses,
  ]);

  return {
    prepareInitialEmptyAddress,
    initialValues,
  };
};
