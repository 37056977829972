import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  ExternalHTMLContent,
  Flex,
  ImageMimeTypes,
  Section,
  SectionType,
  SignatureField,
  TextGroup,
} from '@ac/kiosk-components';

import {
  Body,
  ComplementaryDetailsSection,
  Footer,
  Header,
  PersonalDetailsSection,
  ReservationDetailsSection,
  View,
} from 'components';
import { AdditionalDetailsSection } from 'components/AdditionalDetailsSection/AdditionalDetailsSection';
import { paths } from 'configs/paths';
import { uploadSignedCard } from 'store/electronicRegistrationProcess/actions';
import {
  getAddedInSessionPurchaseElements,
  getAdultCount,
  getChildCount,
  getIsRegistrationCardCancelling,
  getPreferencesGroupOptions,
  getRegistrationCardDetails,
  getSummaryConsents,
} from 'store/electronicRegistrationProcess/selectors';
import {
  getCustomMessages,
  getFieldsConfiguration,
  getGeneralSettings,
  getIsPersonalDetailsVisible,
} from 'store/settings/selectors';
import { useRouter, useScrollPositionRestoration } from 'utils/hooks';
import { mapConsentsData } from 'utils/regCardPresentationDataMappers';
import { mapStayEnhancements } from 'utils/regCardPresentationDataMappers/mapStayEnhancements';

import { PreferenceSelectionList } from './components/PreferenceSelectionList/PreferenceSelectionList';
import { StayEnhancements } from './components/StayEnhancements/StayEnhancements';
import { TermsAndConditionsList } from './components/TermsAndConditionList/TermsAndConditionList';
import { ConsentConfirmationOption } from './types';

import './RegistrationCardConfirmation.scss';

const BODY_ID = 'RegistrationCardConfirmationBodyId';

interface Props {
  dataTestSelector?: string;
}

export const RegistrationCardConfirmation = ({
  dataTestSelector = 'registration-card-confirmation-view',
}: Props): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [signature, setSignature] = useState<Blob>();

  const {
    restoreScrollPosition,
    saveScrollPosition,
  } = useScrollPositionRestoration(`#${BODY_ID}`);

  const isRegistrationCardCancelling = useSelector(
    getIsRegistrationCardCancelling
  );
  const regCardDetails = useSelector(getRegistrationCardDetails);
  const generalSettings = useSelector(getGeneralSettings);
  const customMessages = useSelector(getCustomMessages);
  const adultCount = useSelector(getAdultCount);
  const childCount = useSelector(getChildCount);
  const consents = useSelector(getSummaryConsents);
  const preferencesList = useSelector(getPreferencesGroupOptions);
  const fieldConfiguration = useSelector(getFieldsConfiguration);
  const isPersonalDetailsVisible = useSelector(getIsPersonalDetailsVisible);
  const addedInSessionPurchaseElements = useSelector(
    getAddedInSessionPurchaseElements
  );

  const isPreferencesSectionOn = Boolean(generalSettings?.DISPLAY_PREFERENCES);

  const isAdditionalDetailsEnabled =
    fieldConfiguration?.reservationDetailsObject?.isVisible;

  const isComplementaryDetailsSectionVisible = Boolean(
    generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS?.length
  );

  const onlySelectedPreferences = useMemo(() => {
    const selectedPreferences = preferencesList.map((preferencesGroup) => ({
      ...preferencesGroup,
      preferences: preferencesGroup.preferences.filter(
        (preference) => preference.isSelected
      ),
    }));

    return selectedPreferences.some((list) => list.preferences.length)
      ? selectedPreferences
      : undefined;
  }, [preferencesList]);

  const enhancements = useMemo(() => {
    return mapStayEnhancements(
      addedInSessionPurchaseElements,
      adultCount,
      childCount
    );
  }, [addedInSessionPurchaseElements, adultCount, childCount]);

  const termAndConditionOptions = useMemo((): ConsentConfirmationOption[] => {
    return mapConsentsData(regCardDetails?.profile.consents || [], consents);
  }, [regCardDetails, consents]);

  const handleDisclaimerLinkClick = useCallback(
    (link: string) => {
      saveScrollPosition();
      router.goTo(paths.EXTERNAL_LINK, {
        params: { link: encodeURIComponent(link) },
      });
    },
    [router, saveScrollPosition]
  );

  const handleConfirmClick = useCallback(() => {
    if (signature) {
      dispatch(uploadSignedCard.trigger(signature));
    }
  }, [dispatch, signature]);

  const handleSignatureChange = useCallback((signatureImage?: Blob) => {
    setSignature(signatureImage);
  }, []);

  useEffect(() => {
    restoreScrollPosition(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View dataTestSelector={dataTestSelector}>
      <Header title={t('REGISTRATION_CARD.TITLE')} />
      <Body id={BODY_ID} hasBackgroundImage={false}>
        {regCardDetails && (
          <>
            <ReservationDetailsSection
              reservationData={regCardDetails.reservation}
            />

            {isPersonalDetailsVisible && (
              <PersonalDetailsSection
                dataTestSelector={dataTestSelector?.concat(
                  '-personal-details-section'
                )}
                showOnlyPrimary
              />
            )}
          </>
        )}

        {isComplementaryDetailsSectionVisible && (
          <ComplementaryDetailsSection
            complementaryDetails={regCardDetails?.reservation.reservationHeader}
            dataTestSelector={dataTestSelector?.concat(
              '-complementary-details-section'
            )}
          />
        )}

        {isPreferencesSectionOn && onlySelectedPreferences && (
          <PreferenceSelectionList
            preferenceList={onlySelectedPreferences}
            dataTestSelector={dataTestSelector?.concat('-preferences-section')}
          />
        )}

        {!!enhancements?.length && (
          <StayEnhancements
            items={enhancements}
            dataTestSelector={dataTestSelector?.concat('-stay-enhancements')}
          />
        )}

        <Section type={SectionType.wide}>
          <Flex>
            <Box sizeSm={6}>
              <TermsAndConditionsList options={termAndConditionOptions} />
            </Box>

            {isAdditionalDetailsEnabled && (
              <Box sizeSm={6}>
                <AdditionalDetailsSection
                  dataTestSelector={dataTestSelector?.concat(
                    '-additional-details-section'
                  )}
                  asSubForm={false}
                />
              </Box>
            )}
          </Flex>

          {customMessages?.DISCLAIMER && (
            <Box className="spacing-top-xlg spacing-bottom-xlg">
              <ExternalHTMLContent
                onLinkClick={handleDisclaimerLinkClick}
                content={customMessages.DISCLAIMER}
              />
            </Box>
          )}

          {regCardDetails?.confirmationNumber && (
            <Box className="spacing-top-xxlg">
              <TextGroup
                label={t('REGISTRATION_CARD.REGISTRATION_CARD_NUMBER')}
                value={regCardDetails.confirmationNumber}
              />
            </Box>
          )}

          <Box className="reg-card-summary-signature spacing-top-lg spacing-bottom-xlg">
            <SignatureField
              signatureImageMimeType={ImageMimeTypes.JPEG}
              label={t('REGISTRATION_CARD.SIGNATURE.SIGN_TO_CONFIRM')}
              placeholder={t('REGISTRATION_CARD.SIGNATURE.PLACEHOLDER')}
              onSignatureCompleted={handleSignatureChange}
              onReset={handleSignatureChange}
            />
          </Box>
        </Section>
      </Body>

      <Footer
        hasPrimaryButton
        hasSecondaryButton
        isPrimaryDisabled={!signature}
        primaryLabel={t('SHARED.CONFIRM')}
        onSecondaryClick={router.goBack}
        onPrimaryClick={handleConfirmClick}
        hasCancelButton
        isRegistrationCardCancelling={isRegistrationCardCancelling}
        dataTestSelector={dataTestSelector?.concat('-footer')}
      />
    </View>
  );
};
