import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@ac/kiosk-components';

import { Body, Footer, Header, View } from 'components';
import { useRouter } from 'utils/hooks';

import { ExternalLinkPathParams } from 'types/pathParams';

import './ExternalLink.scss';

interface Props {
  dataTestSelector?: string;
}

export const ExternalLink = ({
  dataTestSelector = 'external-link-view',
}: Props): JSX.Element => {
  const router = useRouter<ExternalLinkPathParams>();
  const { t } = useTranslation();
  const [isLoading, setLoadingState] = useState(true);

  const handleIframeLoad = useCallback(() => {
    setLoadingState(false);
  }, [setLoadingState]);

  return (
    <View>
      <Header
        className="external-link-header"
        title={t('EXTERNAL_LINK.TITLE')}
      />

      <Body className="external-link-body" hasBackgroundImage={false}>
        {isLoading && (
          <div className="external-link-loader-wrapper">
            <Loader />
          </div>
        )}
        <iframe
          onLoad={handleIframeLoad}
          className="external-link-iframe"
          src={decodeURIComponent(router.params.link)}
          title={t('EXTERNAL_LINK.TITLE')}
        />
      </Body>

      <Footer
        className="external-link-footer"
        hasPrimaryButton
        primaryLabel={t('SHARED.CLOSE')}
        onPrimaryClick={router.goBack}
        dataTestSelector={dataTestSelector?.concat('-footer')}
      />
    </View>
  );
};
