import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  FlexDirection,
  RadioButton,
  Text,
  TextSize,
  ValidationMessage,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createRequiredValidator,
  formRadioButtonFieldFactory,
  RadioButtonFieldRenderProps,
  ValidationResult,
} from '@ac/react-infrastructure';

import {
  getFieldsConfiguration,
  getPurposeOfStayEntities,
} from 'store/settings/selectors';
import {
  mapRadioFieldRenderProps,
  translateValidationResult,
} from 'utils/form';
import { useSubForm } from 'utils/form/subFormApi';
import { sortDisplayElements } from 'utils/sorting';

import { BaseObject } from 'types/shared';

interface Props {
  valuePath: string;
  dataTestSelector?: string;
  className?: string;
}

const FormRadioField = formRadioButtonFieldFactory<Pick<BaseObject, string>>();

export const Editable = ({
  valuePath,
  dataTestSelector,
  className,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const subForm = useSubForm([valuePath]);
  const fieldConfiguration = useSelector(getFieldsConfiguration);
  const purposesOfStay = useSelector(getPurposeOfStayEntities);

  const isRequired =
    fieldConfiguration?.reservationDetailsObject?.purposeOfStayField
      ?.isRequired;

  const stayOptions = useMemo(() => {
    return purposesOfStay ? sortDisplayElements(purposesOfStay) : [];
  }, [purposesOfStay]);

  const label = useMemo(() => {
    return [
      t('COMPONENTS.ADDITIONAL_DETAILS_SECTION.PURPOSE_OF_STAY'),
      isRequired ? '*' : undefined,
    ]
      .filter(isDefined)
      .join(' ');
  }, [isRequired, t]);

  return (
    <Box className={className} dataTestSelector={dataTestSelector}>
      <Text className="spacing-bottom-lg" size={TextSize.xs}>
        {label}
      </Text>

      <Flex direction={FlexDirection.column} className="gap-xs">
        {stayOptions.map((option) => (
          <FormRadioField
            key={option.id}
            valuePath={valuePath}
            value={option.id || ''}
            validate={
              isRequired
                ? createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED')
                : undefined
            }
          >
            {(
              fieldRenderProps: RadioButtonFieldRenderProps<string>
            ): JSX.Element => (
              <RadioButton
                {...mapRadioFieldRenderProps(fieldRenderProps)}
                dataTestSelector={dataTestSelector?.concat(
                  '-option-radio-button'
                )}
                label={option.description}
              />
            )}
          </FormRadioField>
        ))}
      </Flex>

      <ValidationMessage
        className="spacing-top-sm"
        validationStatus={
          subForm.submitFailed && subForm.errors
            ? translateValidationResult(subForm.errors as ValidationResult)
            : []
        }
      />
    </Box>
  );
};
