import { PropsWithChildren, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { TestEnvironmentBar, View as BasicView } from '@ac/kiosk-components';

import { getPropertyConfiguration } from 'store/settings/selectors';

import { GetComponentProps } from 'types/shared';

import { iPadChromeBugWorkaround } from './iPadChromeBugWorkaround';

type Props = PropsWithChildren<GetComponentProps<typeof BasicView>>;

export const View = ({ children, ...props }: Props): JSX.Element => {
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => iPadChromeBugWorkaround(divRef.current), []);

  return (
    <BasicView ref={divRef} {...props}>
      {propertyConfiguration?.nonProduction && (
        <TestEnvironmentBar dataTestSelector="view-test-environment-bar" />
      )}
      {children}
    </BasicView>
  );
};
