import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Field, Grid, SelectField } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { getEmailCommunicationTypes } from 'store/settings/selectors';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import { useSubForm } from 'utils/form/subFormApi';
import {
  EmailFormProperties,
  EmailsFormValues,
  FormProperties,
} from 'views/RegistrationCardEditPersonal/types';

import { SubFormActionHeader } from '../../../../SubFormActionHeader/SubFormActionHeader';
import { useEmailsFieldsConfiguration } from '../hooks/useEmailsFieldsConfiguration';

import { ContactEmailsFormField } from './EmailsFormFields';

import './SingleEmailSubForm.scss';

const TEST_SUFFIXES = {
  actionsHeader: '-actions-header',
  type: '-type',
  details: '-details',
};

const GRID_TEMPLATE_COLUMNS = 'repeat(12, 1fr)';

interface Props {
  path: [FormProperties.emails, number];
  isHeaderHidden?: boolean;
  dataTestSelector?: string;
  onRemoveClick?: () => void;
  onSetAsMainClick?: () => void;
}

export const SingleEmailSubForm = ({
  path,
  isHeaderHidden,
  dataTestSelector,
  onRemoveClick,
  onSetAsMainClick,
}: Props): JSX.Element => {
  const [, fieldsIndex] = path;

  const { t } = useTranslation();
  const subFormState = useSubForm<EmailsFormValues>(path);
  const fieldsConfiguration = useEmailsFieldsConfiguration(path);

  const emailTypes = useSelector(getEmailCommunicationTypes);

  const emailOptions = useMemo(
    () => mapSelectOptions(emailTypes, 'description', 'id'),
    [emailTypes]
  );

  useEffect(() => {
    if (!subFormState.dirty) return;

    const selectedType = emailTypes?.find(
      (address) => address.id === subFormState.values?.type
    );

    if (isDefined(selectedType) && !selectedType.isActive) {
      subFormState.api.change(EmailFormProperties.type, undefined);
      subFormState.api.setFieldAsTouched(EmailFormProperties.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFormState.dirty]);

  return (
    <Box dataTestSelector={dataTestSelector}>
      {!isHeaderHidden && (
        <SubFormActionHeader
          className="spacing-bottom-s"
          title={t(
            'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.EMAIL.TITLE_OF_SINGLE',
            {
              count: fieldsIndex + 1,
            }
          )}
          dataTestSelector={dataTestSelector?.concat(
            TEST_SUFFIXES.actionsHeader
          )}
          isMain={subFormState.values?.isPrimary}
          mainControlEnabled
          deleteControlEnabled
          setAsMainButtonDisabled={subFormState.invalid}
          onSetAsMainClick={onSetAsMainClick}
          onRemoveClick={onRemoveClick}
        />
      )}

      <Grid
        gridTemplateColumnsSm={GRID_TEMPLATE_COLUMNS}
        className="gap-lg email-grid-wrapper"
      >
        {fieldsConfiguration.type?.visible && (
          <Grid.Item colSpanSm={4}>
            <ContactEmailsFormField
              validate={fieldsConfiguration.type.validator}
              valuePath={fieldsConfiguration.type?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.type
                  )}
                  options={emailOptions}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.EMAIL.FIELD.TYPE'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  allowClear={fieldsConfiguration.type?.optional}
                  optional={fieldsConfiguration.type?.optional}
                  readonly={fieldsConfiguration.type?.readonly}
                />
              )}
            </ContactEmailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.details?.visible && (
          <Grid.Item className="email-details">
            <ContactEmailsFormField
              validate={fieldsConfiguration.details?.validator}
              valuePath={fieldsConfiguration.details?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.details
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.EMAIL.FIELD.EMAIL'
                  )}
                  placeholder={t('SHARED.FILL')}
                  optional={fieldsConfiguration.details?.optional}
                  readonly={fieldsConfiguration.details?.readonly}
                />
              )}
            </ContactEmailsFormField>
          </Grid.Item>
        )}
      </Grid>
    </Box>
  );
};

SingleEmailSubForm.testSuffixes = TEST_SUFFIXES;
