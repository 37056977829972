import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Field, FieldMode, Grid, SelectField } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import {
  fetchCountryDistricts,
  fetchCountryStates,
} from 'store/additionalDictionaries/actions';
import {
  getCountryDistrictsStatus,
  getCountryStatesStatus,
} from 'store/additionalDictionaries/selectors';
import {
  getAddressTypeEntities,
  getCountryEntities,
  getSuggestedCountries,
} from 'store/settings/selectors';
import { Store } from 'store/types';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import { useSubForm } from 'utils/form/subFormApi';

import {
  AddressFormProperties,
  AddressFormValues,
  FormProperties,
} from '../../../types';
import { SubFormActionHeader } from '../../SubFormActionHeader/SubFormActionHeader';
import { useAddressFieldsConfiguration } from '../hooks/useAddressFieldsConfiguration';

import { AddressFormField } from './AddressFormFields';

import './SingleAddressSubForm.scss';

const TEST_SUFFIXES = {
  district: '-district',
  state: '-state',
  country: '-country',
  postalCode: 'postal-code',
  city: '-city',
  addressLine2: '-address-line-2',
  addressLine1: '-address-line-1',
  type: '-type',
  actionsHeader: '-actions-header',
};

const GRID_TEMPLATE_COLUMNS = 'repeat(12, 1fr)';

interface Props {
  path: [FormProperties.addresses, number];
  isHeaderHidden?: boolean;
  dataTestSelector?: string;
  onRemoveClick?: () => void;
  onSetAsMainClick?: () => void;
}

export const SingleAddressSubForm = ({
  path,
  isHeaderHidden,
  dataTestSelector,
  onRemoveClick,
  onSetAsMainClick,
}: Props): JSX.Element => {
  const [, fieldsIndex] = path;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subFormState = useSubForm<AddressFormValues>(path);
  const fieldsConfiguration = useAddressFieldsConfiguration(path);

  const countries = useSelector(getCountryEntities);
  const suggestedCountries = useSelector(getSuggestedCountries);
  const addressTypes = useSelector(getAddressTypeEntities);
  const states = useSelector((state: Store) =>
    getCountryStatesStatus(state, subFormState.values?.country)
  );
  const districts = useSelector((state: Store) =>
    getCountryDistrictsStatus(state, subFormState.values?.country)
  );

  const addressOptions = useMemo(
    () => mapSelectOptions(addressTypes, 'description', 'id'),
    [addressTypes]
  );
  const countryOptions = useMemo(
    () => mapSelectOptions(countries, 'name', 'code'),
    [countries]
  );
  const suggestedCountryOptions = useMemo(
    () => suggestedCountries?.map(({ code }) => code),
    [suggestedCountries]
  );
  const statesOptions = useMemo(
    () => mapSelectOptions(states?.result, 'name', 'code'),
    [states?.result]
  );
  const districtsOptions = useMemo(
    () => mapSelectOptions(districts?.result, 'description', 'id'),
    [districts?.result]
  );

  useEffect(() => {
    if (!subFormState.values?.country) return;

    if (fieldsConfiguration.state?.visible && !states?.result) {
      dispatch(fetchCountryStates.trigger(subFormState.values.country));
    }

    if (fieldsConfiguration.district?.visible && !districts?.result) {
      dispatch(fetchCountryDistricts.trigger(subFormState.values.country));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subFormState.values?.country]);

  useEffect(() => {
    if (!subFormState.dirty) return;

    const selectedType = addressTypes?.find(
      (address) => address.id === subFormState.values?.type
    );

    if (isDefined(selectedType) && !selectedType.isActive) {
      subFormState.api.change(AddressFormProperties.type, undefined);
      subFormState.api.setFieldAsTouched(AddressFormProperties.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFormState.dirty]);

  return (
    <Box dataTestSelector={dataTestSelector}>
      {!isHeaderHidden && (
        <SubFormActionHeader
          className="spacing-bottom-s"
          title={t('REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.TITLE_OF_SINGLE', {
            count: fieldsIndex + 1,
          })}
          dataTestSelector={dataTestSelector?.concat(
            TEST_SUFFIXES.actionsHeader
          )}
          isMain={subFormState.values?.isPrimary}
          mainControlEnabled
          deleteControlEnabled
          setAsMainButtonDisabled={subFormState.invalid}
          onSetAsMainClick={onSetAsMainClick}
          onRemoveClick={onRemoveClick}
        />
      )}
      <Grid
        gridTemplateColumnsSm={GRID_TEMPLATE_COLUMNS}
        className="gap-lg address-grid-wrapper"
      >
        <Grid.Item rowSpanSm={1} colSpanSm={12} className="address-row-subgrid">
          {fieldsConfiguration.type?.visible && (
            <Grid.Item colSpanSm={4}>
              <AddressFormField
                validate={fieldsConfiguration.type?.validator}
                valuePath={fieldsConfiguration.type?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => (
                  <SelectField
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    dataTestSelector={dataTestSelector?.concat(
                      TEST_SUFFIXES.type
                    )}
                    options={addressOptions}
                    label={t(
                      'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.TYPE'
                    )}
                    placeholder={t('SHARED.SELECT')}
                    allowClear={fieldsConfiguration.type?.optional}
                    optional={fieldsConfiguration.type?.optional}
                    readonly={fieldsConfiguration.type?.readonly}
                  />
                )}
              </AddressFormField>
            </Grid.Item>
          )}

          {fieldsConfiguration.addressLine1?.visible && (
            <Grid.Item className="address-details">
              <AddressFormField
                validate={fieldsConfiguration.addressLine1?.validator}
                valuePath={fieldsConfiguration.addressLine1?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => (
                  <Field
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    dataTestSelector={dataTestSelector?.concat(
                      TEST_SUFFIXES.addressLine1
                    )}
                    label={t(
                      'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.ADDRESS_LINE_1'
                    )}
                    placeholder={t('SHARED.FILL')}
                    maxLength={255}
                    optional={fieldsConfiguration.addressLine1?.optional}
                    readonly={fieldsConfiguration.addressLine1?.readonly}
                  />
                )}
              </AddressFormField>
            </Grid.Item>
          )}
        </Grid.Item>

        <Grid.Item rowSpanSm={1} colSpanSm={12} className="address-row-subgrid">
          {fieldsConfiguration.addressLine2?.visible && (
            <Grid.Item colSpanSm={12}>
              <AddressFormField
                validate={fieldsConfiguration.addressLine2?.validator}
                valuePath={fieldsConfiguration.addressLine2?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => (
                  <Field
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    dataTestSelector={dataTestSelector?.concat(
                      TEST_SUFFIXES.addressLine2
                    )}
                    label={t(
                      'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.ADDRESS_LINE_2'
                    )}
                    placeholder={t('SHARED.FILL')}
                    maxLength={255}
                    optional={fieldsConfiguration.addressLine2?.optional}
                    readonly={fieldsConfiguration.addressLine2?.readonly}
                  />
                )}
              </AddressFormField>
            </Grid.Item>
          )}
        </Grid.Item>

        <Grid.Item rowSpanSm={1} colSpanSm={12} className="address-row-subgrid">
          {fieldsConfiguration.city?.visible && (
            <Grid.Item colSpanSm={4}>
              <AddressFormField
                validate={fieldsConfiguration.city?.validator}
                valuePath={fieldsConfiguration.city?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => (
                  <Field
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    dataTestSelector={dataTestSelector?.concat(
                      TEST_SUFFIXES.city
                    )}
                    label={t(
                      'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.CITY'
                    )}
                    placeholder={t('SHARED.FILL')}
                    maxLength={50}
                    optional={fieldsConfiguration.city?.optional}
                    readonly={fieldsConfiguration.city?.readonly}
                  />
                )}
              </AddressFormField>
            </Grid.Item>
          )}

          {fieldsConfiguration.postalCode?.visible && (
            <Grid.Item colSpanSm={3}>
              <AddressFormField
                validate={fieldsConfiguration.postalCode?.validator}
                valuePath={fieldsConfiguration.postalCode?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => (
                  <Field
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    dataTestSelector={dataTestSelector?.concat(
                      TEST_SUFFIXES.postalCode
                    )}
                    label={t(
                      'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.POST_CODE'
                    )}
                    placeholder={t('SHARED.FILL')}
                    mode={FieldMode.numeric}
                    maxLength={20}
                    optional={fieldsConfiguration.postalCode?.optional}
                    readonly={fieldsConfiguration.postalCode?.readonly}
                  />
                )}
              </AddressFormField>
            </Grid.Item>
          )}
        </Grid.Item>

        <Grid.Item rowSpanSm={1} colSpanSm={12} className="address-row-subgrid">
          {fieldsConfiguration.country?.visible && (
            <Grid.Item colSpanSm={4}>
              <AddressFormField
                validate={fieldsConfiguration.country?.validator}
                valuePath={fieldsConfiguration.country?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => {
                  const fieldRenderProps = mapFieldRenderProps(
                    fieldFieldRenderProps
                  );

                  return (
                    <SelectField
                      {...fieldRenderProps}
                      dataTestSelector={dataTestSelector?.concat(
                        TEST_SUFFIXES.country
                      )}
                      suggestedOptionValues={suggestedCountryOptions}
                      options={countryOptions}
                      label={t(
                        'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.COUNTRY'
                      )}
                      placeholder={t('SHARED.SELECT')}
                      allowClear={fieldsConfiguration.country?.optional}
                      optional={fieldsConfiguration.country?.optional}
                      readonly={fieldsConfiguration.country?.readonly}
                      onChange={(value: string): void => {
                        if (value !== fieldFieldRenderProps.input.value) {
                          subFormState.api.changeMany({
                            country: value,
                            state: undefined,
                            district: undefined,
                          });
                        }
                      }}
                    />
                  );
                }}
              </AddressFormField>
            </Grid.Item>
          )}

          {fieldsConfiguration.state?.visible && (
            <Grid.Item colSpanSm={4}>
              <AddressFormField
                validate={fieldsConfiguration.state?.validator}
                valuePath={fieldsConfiguration.state?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => (
                  <SelectField
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    dataTestSelector={dataTestSelector?.concat(
                      TEST_SUFFIXES.state
                    )}
                    label={t(
                      'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.STATE'
                    )}
                    placeholder={t('SHARED.SELECT')}
                    options={statesOptions}
                    disabled={!subFormState.values?.country}
                    optionsLoading={states?.isFetching}
                    allowClear={fieldsConfiguration.state?.optional}
                    optional={fieldsConfiguration.state?.optional}
                    readonly={fieldsConfiguration.state?.readonly}
                  />
                )}
              </AddressFormField>
            </Grid.Item>
          )}

          {fieldsConfiguration.district?.visible && (
            <Grid.Item colSpanSm={4}>
              <AddressFormField
                validate={fieldsConfiguration.district?.validator}
                valuePath={fieldsConfiguration.district?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => (
                  <SelectField
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    dataTestSelector={dataTestSelector?.concat(
                      TEST_SUFFIXES.district
                    )}
                    label={t(
                      'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.FIELD.DISTRICT'
                    )}
                    placeholder={t('SHARED.SELECT')}
                    options={districtsOptions}
                    disabled={!subFormState.values?.country}
                    optionsLoading={districts?.isFetching}
                    allowClear={fieldsConfiguration.district?.optional}
                    optional={fieldsConfiguration.district?.optional}
                    readonly={fieldsConfiguration.district?.readonly}
                  />
                )}
              </AddressFormField>
            </Grid.Item>
          )}
        </Grid.Item>
      </Grid>
    </Box>
  );
};

SingleAddressSubForm.testSuffixes = TEST_SUFFIXES;
