import { all, fork, ForkEffect } from 'redux-saga/effects';

/**
 * global sagas
 */
import { additionalDictionariesSagas } from 'store/additionalDictionaries/sagas';
import { appSagas } from 'store/app/sagas';
import { electronicRegistrationProcessSagas } from 'store/electronicRegistrationProcess/sagas';
import { settingsSagas } from 'store/settings/sagas';
/**
 * View's sagas
 */
import { loginViewSagas } from 'views/Login/store/sagas';
import { registrationCardEditComplementaryViewSagas } from 'views/RegistrationCardEditComplementary/store/sagas';
import { registrationCardEditPersonalViewSagas } from 'views/RegistrationCardEditPersonal/store/sagas';
import { registrationCardPurchaseElementsViewSagas } from 'views/RegistrationCardPurchaseElements/store/sagas';

import { SagasGenerator } from 'types/shared';

export function* rootSagas(additionalSagas?: ForkEffect[]): SagasGenerator {
  yield all([
    /**
     * global sagas
     */
    fork(additionalDictionariesSagas),
    fork(appSagas),
    fork(electronicRegistrationProcessSagas),
    fork(settingsSagas),
    /**
     * View's sagas
     */
    fork(loginViewSagas),
    fork(registrationCardEditComplementaryViewSagas),
    fork(registrationCardPurchaseElementsViewSagas),
    fork(registrationCardEditPersonalViewSagas),

    ...(additionalSagas ? additionalSagas : []),
  ]);
}
