import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  ButtonPattern,
  Flex,
  FlexDirection,
  IconTypes,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { getFieldsConfiguration } from 'store/settings/selectors';
import { useSubForm } from 'utils/form/subFormApi';
import { useEmailsInitialValues } from 'views/RegistrationCardEditPersonal/hooks/useFormInitialValues/useEmailsInitialValues';
import {
  EntityType,
  useRemoveEntity,
} from 'views/RegistrationCardEditPersonal/hooks/useRemoveEntity';
import { useSetEntityAsMain } from 'views/RegistrationCardEditPersonal/hooks/useSetEntityAsMain';
import {
  EmailsFormValues,
  FormProperties,
} from 'views/RegistrationCardEditPersonal/types';

import { ContactEmailsFormFieldCollection } from './components/EmailsFormFields';
import { SingleEmailSubForm } from './components/SingleEmailSubForm';

const TEST_SUFFIXES = {
  notProvided: '-not-provided-description',
  singleEmail: '-single-email',
  addAdditionalButton: '-add-additional-button',
};

const EMAILS_PATH: [FormProperties.emails] = [FormProperties.emails];

interface Props {
  dataTestSelector?: string;
}

export const EmailsSubForm = ({ dataTestSelector }: Props): JSX.Element => {
  const { t } = useTranslation();
  const setAsMain = useSetEntityAsMain();
  const removeEntity = useRemoveEntity(EntityType.Emails);
  const { prepareInitialEmptyEmail } = useEmailsInitialValues();
  const subFormState = useSubForm<EmailsFormValues[]>(EMAILS_PATH, {
    valid: true,
  });

  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const isEmailRequired =
    fieldsConfiguration?.emailObjectCollection?.isRequired;

  return (
    <Box dataTestSelector={dataTestSelector}>
      <Text size={TextSize.lg} className="spacing-bottom-xlg">
        {t('REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.EMAIL.TITLE')}
      </Text>
      <ContactEmailsFormFieldCollection
        subscription={{}}
        valuePath={EMAILS_PATH}
      >
        {(fieldFieldArrayRenderProps): JSX.Element => {
          const emailsAmount = subFormState.api.getState()?.values?.length ?? 0;
          const isTheOnlyOneEntity = emailsAmount === 1;

          return (
            <>
              {emailsAmount === 0 ? (
                <Text
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.notProvided
                  )}
                  className="spacing-bottom-xlg"
                >
                  {t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.EMAIL.NOT_PROVIDED'
                  )}
                </Text>
              ) : (
                <Flex direction={FlexDirection.column} className="gap-xlg">
                  {fieldFieldArrayRenderProps.fields.map(
                    (path, index, name) => (
                      <SingleEmailSubForm
                        key={name}
                        path={path}
                        dataTestSelector={dataTestSelector?.concat(
                          TEST_SUFFIXES.singleEmail
                        )}
                        isHeaderHidden={isTheOnlyOneEntity && isEmailRequired}
                        onRemoveClick={(): void => {
                          removeEntity(
                            subFormState.api.getState(),
                            fieldFieldArrayRenderProps,
                            index
                          );
                        }}
                        onSetAsMainClick={(): void => {
                          setAsMain(
                            subFormState.api.getState(),
                            fieldFieldArrayRenderProps,
                            index
                          );
                        }}
                      />
                    )
                  )}
                </Flex>
              )}
              <Button
                icon={IconTypes.plus}
                pattern={ButtonPattern.tertiary}
                disabled={!subFormState.valid}
                dataTestSelector={dataTestSelector?.concat(
                  TEST_SUFFIXES.addAdditionalButton
                )}
                onClick={(): void =>
                  fieldFieldArrayRenderProps.fields.push(
                    prepareInitialEmptyEmail(!emailsAmount)
                  )
                }
              >
                {t(
                  !emailsAmount
                    ? 'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.EMAIL.ADD_NEW_BUTTON'
                    : 'REGISTRATION_CARD_EDIT_PERSONAL.SHARED.ADD_NEXT'
                )}
              </Button>
            </>
          );
        }}
      </ContactEmailsFormFieldCollection>
    </Box>
  );
};

EmailsSubForm.testSuffixes = TEST_SUFFIXES;
