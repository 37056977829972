import i18next from 'i18next';
import { call, select } from 'redux-saga/effects';

import {
  convertImageTo,
  ImageMimeTypes,
  PDFCreator,
} from '@ac/kiosk-components';
import {
  getSafeExternalContent,
  isDefined,
} from '@ac/library-utils/dist/utils';

import {
  KioskAddress,
  KioskCommunicationChannel,
  KioskConfigurationConsent,
  KioskConfigurationProperty,
  KioskIdentityDocument,
  KioskRegCardDetails,
  KioskReservation,
  KioskReservationHeaderDefinition,
} from 'api/KioskApi/entries';
import {
  CustomMessagesSettingsStorage,
  DateTimeFormats,
  GeneralSettingsStorage,
  ImagesSettingsStorage,
} from 'store/settings/interfaces';
import { SectionConfiguration } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import {
  getCustomMessages,
  getDateTimeFormats,
  getFieldsConfiguration,
  getGeneralSettings,
  getImages,
  getIsPersonalDetailsVisible,
  getPropertyConfiguration,
  getReservationHeaderDefinition,
} from 'store/settings/selectors';
import { blobToBase64, DateManager, objectUrlToBlob } from 'utils';
import {
  AdditionalDetailsPresentationDataItem,
  mapComplementaryData,
  mapConsentsData,
  mapPreferenceSectionData,
  mapReservationData,
  ProfilePresentationDataElement,
  ReservationPresentationDataElement,
} from 'utils/regCardPresentationDataMappers';
import { mapAddressData } from 'utils/regCardPresentationDataMappers/mapAddressData';
import { mapContactsData } from 'utils/regCardPresentationDataMappers/mapContactsData';
import { mapDocumentsData } from 'utils/regCardPresentationDataMappers/mapDocumentsData';
import { mapPersonalData } from 'utils/regCardPresentationDataMappers/mapPersonalData';
import { mapStayEnhancements } from 'utils/regCardPresentationDataMappers/mapStayEnhancements';

import { SagasGenerator } from 'types/shared';

import { PreferenceOptionsGroup } from '../interfaces/preferenceOptions/preferenceOptionsGroup';
import { SessionPurchaseElementDetails } from '../interfaces/SessionPurchaseElementDetails';
import {
  getAddedInSessionPurchaseElements,
  getAdultCount,
  getChildCount,
  getIsPurchaseElementSelectionEnabled,
  getPreferencesGroupOptions,
  getRegistrationCardDetails,
  getSummaryConsents,
} from '../selectors';
import {
  getSortedProfileAddresses,
  getSortedProfileCommunicationChannels,
  getValidProfileDocuments,
} from '../selectors/profile';
import { getEtd, getReservation } from '../selectors/reservation';
import {
  createRegistrationCardPdfContent,
  RegistrationCardPdfConfig,
  RegistrationCardPdfData,
} from '../utils/createRegistrationCardPdfContent';

function* getReservationData(): SagasGenerator<ReservationPresentationDataElement> {
  const reservation: KioskReservation | undefined = yield select(
    getReservation
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );

  if (!reservation) {
    return {
      sections: [],
    };
  }

  const reservationData = mapReservationData(reservation, {
    longDateFormat: dateTimeFormats?.longDateFormat,
    timeFormat: dateTimeFormats?.timeFormat,
    isMembershipEnabled: generalSettings?.DISPLAY_MEMBERSHIP,
    fieldsConfiguration: fieldsConfiguration?.reservationDetailsObject,
  });

  return reservationData;
}

function* getPersonalData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const cardDetails: KioskRegCardDetails = yield select(
    getRegistrationCardDetails
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );

  const personalData = mapPersonalData(cardDetails, {
    fieldsConfiguration,
    shortDateFormat: dateTimeFormats?.shortDateFormat,
  });

  return personalData;
}

function* getAddressesData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const addresses: KioskAddress[] | undefined = yield select(
    getSortedProfileAddresses
  );
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const addressFieldsConfiguration =
    fieldsConfiguration?.addressesObjectCollection;

  const addressesData = mapAddressData(addresses, {
    fieldConfiguration: addressFieldsConfiguration,
    showOnlyPrimary: true,
  });

  return addressesData;
}

function* getContactsData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const communicationChannels:
    | KioskCommunicationChannel[]
    | undefined = yield select(getSortedProfileCommunicationChannels);

  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );

  const contactsData = mapContactsData(communicationChannels, {
    showOnlyPrimary: true,
    emailFieldsConfiguration: fieldsConfiguration?.emailObjectCollection,
    mobileFieldsConfiguration: fieldsConfiguration?.mobileObjectCollection,
    phoneFieldsConfiguration: fieldsConfiguration?.phoneObjectCollection,
  });

  return contactsData;
}

function* getDocumentsData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const identityDocuments: KioskIdentityDocument[] | undefined = yield select(
    getValidProfileDocuments
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );

  const documentsData = mapDocumentsData(identityDocuments, {
    fieldsConfiguration: fieldsConfiguration?.identityDocumentObjectCollection,
    shortDateFormat: dateTimeFormats?.shortDateFormat,
  });

  return documentsData;
}

function* getAdditionalDetailsData(): SagasGenerator<
  AdditionalDetailsPresentationDataItem[]
> {
  const etd: string | undefined = yield select(getEtd);
  const reservation: KioskReservation | undefined = yield select(
    getReservation
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const reservationDetailsObject =
    fieldsConfiguration?.reservationDetailsObject;

  const isPurposeOfStayEnabled =
    reservationDetailsObject?.purposeOfStayField?.isVisible;

  const isEtdEnabled =
    reservationDetailsObject?.estimatedTimeOfDepartureField?.isVisible;

  return [
    isPurposeOfStayEnabled
      ? {
          label: i18next.t(
            'COMPONENTS.ADDITIONAL_DETAILS_SECTION.PURPOSE_OF_STAY'
          ),
          value: reservation?.purposeOfStay?.description,
        }
      : undefined,
    isEtdEnabled
      ? {
          label: i18next.t('COMPONENTS.ADDITIONAL_DETAILS_SECTION.ETD'),
          value:
            etd &&
            DateManager.getFormattedTime(etd, dateTimeFormats?.timeFormat),
        }
      : undefined,
  ].filter(isDefined);
}

function* getPurchaseElementsData(): SagasGenerator<string[] | undefined> {
  const addedInSessionPurchaseElements: SessionPurchaseElementDetails[] = yield select(
    getAddedInSessionPurchaseElements
  );
  const adultCount: number = yield select(getAdultCount);
  const childCount: number = yield select(getChildCount);

  const purchaseElements = mapStayEnhancements(
    addedInSessionPurchaseElements,
    adultCount,
    childCount
  )?.map((item) => {
    const totalPrice = `${i18next.t(
      'REGISTRATION_CARD_PURCHASE_ELEMENTS.TOTAL_PRICE'
    )} ${item.price}`;

    const itemsCounter = i18next.t('REGISTRATION_CARD_PURCHASE_ELEMENTS.ITEM', {
      count: item.amount,
    });

    return !!item.amount && item.amount > 1
      ? `${item.name} (${itemsCounter}, ${totalPrice})`
      : `${item.name} (${totalPrice})`;
  });

  return purchaseElements;
}

function* getLogoBase64(): SagasGenerator<string | undefined> {
  const images: ImagesSettingsStorage | undefined = yield select(getImages);

  const logoBlob: Blob | undefined = images?.LOGO
    ? yield objectUrlToBlob(images.LOGO)
    : undefined;

  const convertedLogo: Blob | undefined =
    logoBlob && logoBlob.type === ImageMimeTypes.PNG
      ? yield convertImageTo(logoBlob, ImageMimeTypes.JPEG)
      : logoBlob;

  const logoBase64: string | undefined = convertedLogo
    ? yield blobToBase64(convertedLogo)
    : undefined;

  return logoBase64;
}

function* getPDFPresentationData(
  signatureBlob: Blob
): SagasGenerator<RegistrationCardPdfData> {
  const consents: KioskConfigurationConsent[] | undefined = yield select(
    getSummaryConsents
  );
  const reservationHeaderDefinition:
    | KioskReservationHeaderDefinition[]
    | undefined = yield select(getReservationHeaderDefinition);

  const propertyConfiguration:
    | KioskConfigurationProperty
    | undefined = yield select(getPropertyConfiguration);
  const customMessages:
    | CustomMessagesSettingsStorage
    | undefined = yield select(getCustomMessages);
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );

  const cardDetails: KioskRegCardDetails = yield select(
    getRegistrationCardDetails
  );

  const preferencesOptions: PreferenceOptionsGroup[] = yield select(
    getPreferencesGroupOptions
  );

  const date = DateManager.getFormattedDate(
    propertyConfiguration?.businessDate,
    dateTimeFormats?.shortDateFormat
  );
  const time = DateManager.getFormattedTime(
    new Date(),
    dateTimeFormats?.timeFormat
  );

  const reservationData: ReservationPresentationDataElement = yield call(
    getReservationData
  );
  const personalData: ProfilePresentationDataElement[] = yield call(
    getPersonalData
  );
  const addressesData: ProfilePresentationDataElement[] = yield call(
    getAddressesData
  );
  const contactsData: ProfilePresentationDataElement[] = yield call(
    getContactsData
  );
  const documentsData: ProfilePresentationDataElement[] = yield call(
    getDocumentsData
  );
  const additionalDetailsData: AdditionalDetailsPresentationDataItem[] = yield call(
    getAdditionalDetailsData
  );
  const purchaseElementsData: string[] | undefined = yield call(
    getPurchaseElementsData
  );

  const logoBase64: string | undefined = yield call(getLogoBase64);
  const signatureBase64: string = yield blobToBase64(signatureBlob);

  const pdfPresentationData: RegistrationCardPdfData = {
    reservationSubsections: reservationData,
    personalSubsection: personalData,
    addressSection: addressesData,
    contactSection: contactsData,
    documentSubsenctions: documentsData,
    complementaryDetails: mapComplementaryData(
      cardDetails.reservation.reservationHeader,
      reservationHeaderDefinition,
      {
        shortDateFormat: dateTimeFormats?.shortDateFormat,
        timeFormat: dateTimeFormats?.timeFormat,
        enabledCustomFields: generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS,
      }
    ),
    preferenceSection: mapPreferenceSectionData(preferencesOptions),
    consentsSection: mapConsentsData(cardDetails.profile.consents, consents),
    additionalDetailsSection: additionalDetailsData,
    disclaimer: getSafeExternalContent(customMessages?.DISCLAIMER),
    registrationCardNumber: cardDetails.confirmationNumber,
    creationDateTime: `${date} ${time}`,
    signature: signatureBase64,
    logo: logoBase64,
    consentsBodySection: consents,
    purchaseElements: purchaseElementsData,
  };

  return pdfPresentationData;
}

function* getPDFConfig(): SagasGenerator<RegistrationCardPdfConfig> {
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );

  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );

  const isPersonalDetailsVisible: boolean = yield select(
    getIsPersonalDetailsVisible
  );

  const isPurchaseElementSelectionEnabled = yield select(
    getIsPurchaseElementSelectionEnabled
  );

  const pdfConfig: RegistrationCardPdfConfig = {
    isPersonalSectionEnabled: isPersonalDetailsVisible,
    isPersonalDetailsEnabled:
      fieldsConfiguration?.guestPersonalDetailsObject?.isVisible ?? true,
    isAddressEnabled:
      fieldsConfiguration?.addressesObjectCollection?.isVisible ?? true,
    isContactEnabled:
      (fieldsConfiguration?.emailObjectCollection?.isVisible ||
        fieldsConfiguration?.phoneObjectCollection?.isVisible ||
        fieldsConfiguration?.mobileObjectCollection?.isVisible) ??
      true,
    isDocumentEnabled:
      fieldsConfiguration?.identityDocumentObjectCollection?.isVisible ?? true,
    isAdditionalDetailsEnabled:
      fieldsConfiguration?.reservationDetailsObject?.isVisible,
    isComplementaryDetailsAvailable: Boolean(
      generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS?.length
    ),
    isPrintConsentDescription: generalSettings?.PRINT_CONSENT_DESCRIPTION,
    isPreferencesEnabled: generalSettings?.DISPLAY_PREFERENCES,
    isPurchaseElementSelectionEnabled,
  };

  return pdfConfig;
}

export function* createPDF(signature: Blob): SagasGenerator<Blob> {
  const pdfPresentationData: RegistrationCardPdfData = yield call(
    getPDFPresentationData,
    signature
  );
  const pdfConfig: RegistrationCardPdfConfig = yield call(getPDFConfig);
  const pdfContent: string = createRegistrationCardPdfContent(
    pdfPresentationData,
    pdfConfig
  );

  const pdfBlob: Blob = yield new PDFCreator().createPDF(
    pdfContent,
    i18next.dir()
  );

  return pdfBlob;
}
