import { KioskIdentityDocument } from 'api/KioskApi/entries';
import {
  FieldConfiguration,
  IdentityDocumentSection,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

const DOCUMENT_CONFIGURATION_MAP: Record<
  keyof IdentityDocumentSection,
  keyof KioskIdentityDocument | undefined
> = {
  expiryDateField: 'expiryDate',
  numberField: 'number',
  typeField: 'documentType',
  isEditable: undefined,
  isRequired: undefined,
  isVisible: undefined,
};

export const validateDocuments = (
  documentsConfiguration: IdentityDocumentSection,
  documents?: KioskIdentityDocument[]
): boolean => {
  if (documentsConfiguration.isRequired && !documents?.length) return false;
  if (!documents?.length) return true;

  return documents.every((document) => {
    return Object.entries(documentsConfiguration).every(
      ([key, fieldConfiguration]: [
        keyof IdentityDocumentSection,
        FieldConfiguration
      ]): boolean => {
        const documentRecordKey = DOCUMENT_CONFIGURATION_MAP[key];

        if (documentRecordKey && fieldConfiguration?.isRequired) {
          return !!document[documentRecordKey];
        }

        return true;
      }
    );
  });
};
