import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import { COMMUNICATION_MODES } from 'configs';
import {
  EmailSection,
  MobileSection,
  PhoneSection,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

import { ProfilePresentationDataElement } from './types';

interface ConctactVisibilityConfig {
  phoneFieldsConfiguration?: PhoneSection;
  mobileFieldsConfiguration?: MobileSection;
  emailFieldsConfiguration?: EmailSection;
  showOnlyPrimary?: boolean;
}

const getConfigurationByMode = (
  contact: KioskCommunicationChannel,
  settings?: ConctactVisibilityConfig
): PhoneSection | MobileSection | EmailSection | undefined => {
  switch (contact.mode?.code) {
    case COMMUNICATION_MODES.email:
      return settings?.emailFieldsConfiguration;
    case COMMUNICATION_MODES.phone:
      return settings?.phoneFieldsConfiguration;
    case COMMUNICATION_MODES.mobile:
      return settings?.mobileFieldsConfiguration;
    default:
      return undefined;
  }
};

export const mapContactsData = (
  contacts: KioskCommunicationChannel[] = [],
  settings?: ConctactVisibilityConfig
): ProfilePresentationDataElement[] => {
  const visibleContacts = contacts.filter((contact) => {
    return getConfigurationByMode(contact, settings)?.isVisible;
  });

  const contactsArray = settings?.showOnlyPrimary
    ? visibleContacts?.filter((contact) => contact.isPrimary)
    : visibleContacts;

  return [
    ...contactsArray.map((contact) => {
      const config = getConfigurationByMode(contact, settings);

      return {
        isCollapsible: !contact.isPrimary,
        sectionData: [
          {
            value: config?.detailsField?.isVisible
              ? contact.details
              : undefined,
            label: config?.typeField?.isVisible
              ? contact.type?.description
              : undefined,
          },
        ],
      };
    }),
  ];
};
