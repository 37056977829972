import { FeatureTogglesCodeMap } from '../utils/normalizeFeatureToggles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureTogglesStorage {
  /**
   * Add feature toggles that have to be stored from configuration response.
   */
}

export const featureTogglesCodesMap: FeatureTogglesCodeMap<FeatureTogglesStorage> = {};
