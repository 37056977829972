import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import { getSortedProfileMobiles } from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getDefaultMobileType,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import {
  PhoneFormProperties,
  PhonesFormValues,
} from 'views/RegistrationCardEditPersonal/types';

interface MobilesInitialValuesResult {
  prepareInitialEmptyMobile: (isPrimary: boolean) => PhonesFormValues;
  initialValues: PhonesFormValues[] | undefined;
}

export const useMobilesInitialValues = (): MobilesInitialValuesResult => {
  const mobiles = useSelector(getSortedProfileMobiles);
  const defaultMobileType = useSelector(getDefaultMobileType);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const mobilesConfiguration = fieldsConfiguration?.mobileObjectCollection;

  const prepareInitialEmptyMobile = useCallback(
    (isPrimary: boolean): PhonesFormValues => {
      return {
        [PhoneFormProperties.isPrimary]: isPrimary,
        [PhoneFormProperties.type]: defaultMobileType?.id,
      };
    },
    [defaultMobileType]
  );

  const prepareSingleMobileInitialValues = useCallback(
    (
      phone?: Partial<KioskCommunicationChannel>
    ): PhonesFormValues | undefined => {
      return {
        [PhoneFormProperties.id]: phone?.id,
        [PhoneFormProperties.isPrimary]: phone?.isPrimary || false,
        [PhoneFormProperties.phoneNumber]: phone?.details,
        [PhoneFormProperties.type]: phone?.type?.id,
      } as PhonesFormValues;
    },
    []
  );

  const initialValues = useMemo(() => {
    if (!mobiles?.length && mobilesConfiguration?.isRequired) {
      return [prepareInitialEmptyMobile(true)];
    }

    return mobiles?.map(prepareSingleMobileInitialValues).filter(isDefined);
  }, [
    mobiles,
    mobilesConfiguration,
    prepareSingleMobileInitialValues,
    prepareInitialEmptyMobile,
  ]);

  return {
    prepareInitialEmptyMobile,
    initialValues,
  };
};
