import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  Section,
  SectionType,
  Text,
  TextGroup,
  TextSize,
} from '@ac/kiosk-components';

import { PreferenceOptionsGroup } from 'store/electronicRegistrationProcess/interfaces/preferenceOptions/preferenceOptionsGroup';
import { mapPreferenceSectionData } from 'utils/regCardPresentationDataMappers';

import './PreferenceSelectionList.scss';

type PreferenceSelectionListProps = {
  preferenceList: PreferenceOptionsGroup[];
  dataTestSelector?: string;
};

export const PreferenceSelectionList = ({
  preferenceList,
  dataTestSelector,
}: PreferenceSelectionListProps): JSX.Element | null => {
  const { t } = useTranslation();

  const groupValuesPreferenceRow = useMemo(
    () => mapPreferenceSectionData(preferenceList),
    [preferenceList]
  );

  return (
    <Section
      className={classNames('preference-selection-list-wrapper')}
      type={SectionType.wide}
      dataTestSelector={dataTestSelector}
    >
      <Text className="spacing-bottom-xlg" size={TextSize.xlg}>
        {t('REGISTRATION_CARD.GUEST_PREFERENCES.TITLE')}
      </Text>
      <ul
        data-test-selector={dataTestSelector?.concat('-section-list')}
        className={classNames('preference-selection-list-list')}
      >
        {groupValuesPreferenceRow.map((row) => (
          <li
            className="spacing-top-sm"
            key={row.label}
            data-test-selector={dataTestSelector?.concat('-section-list-item')}
          >
            <TextGroup label={row.label} value={row.value} />
          </li>
        ))}
      </ul>
    </Section>
  );
};
