import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskIdentityDocument } from 'api/KioskApi/entries';
import { getValidProfileDocuments } from 'store/electronicRegistrationProcess/selectors/profile';
import { getFieldsConfiguration } from 'store/settings/selectors';
import { DateManager } from 'utils';
import {
  DocumentFormProperties,
  DocumentFormValues,
} from 'views/RegistrationCardEditPersonal/types';

interface DocumentsInitialValuesResult {
  prepareInitialEmptyDocument: () => DocumentFormValues;
  initialValues: DocumentFormValues[] | undefined;
}

export const useDocumentsInitialValues = (): DocumentsInitialValuesResult => {
  const documents = useSelector(getValidProfileDocuments);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const documentsConfiguration =
    fieldsConfiguration?.identityDocumentObjectCollection;

  const prepareInitialEmptyDocument = useCallback((): DocumentFormValues => {
    return {};
  }, []);

  const prepareSingleDocumentInitialValues = useCallback(
    (
      document: Partial<KioskIdentityDocument>
    ): DocumentFormValues | undefined => {
      return {
        [DocumentFormProperties.id]: document?.id,
        [DocumentFormProperties.expiryDate]:
          document?.expiryDate &&
          DateManager.getFormattedDate(
            document.expiryDate,
            DATE_PICKER_DATE_FORMAT
          ),
        [DocumentFormProperties.number]: document?.number,
        [DocumentFormProperties.type]: document?.documentType?.id,
      };
    },
    []
  );

  const initialValues = useMemo(() => {
    if (!documents?.length && documentsConfiguration?.isRequired) {
      return [prepareInitialEmptyDocument()];
    }

    return documents?.map(prepareSingleDocumentInitialValues).filter(isDefined);
  }, [
    documents,
    documentsConfiguration,
    prepareInitialEmptyDocument,
    prepareSingleDocumentInitialValues,
  ]);

  return {
    prepareInitialEmptyDocument,
    initialValues,
  };
};
