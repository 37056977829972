import { createSelector } from 'reselect';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  AdditionalNamesTypes,
  KioskAddress,
  KioskCommunicationChannel,
  KioskIdentityDocument,
  KioskProfile,
} from 'api/KioskApi/entries';
import {
  getAddressTypeEntities,
  getCommunicationModeOrder,
  getEmailCommunicationTypes,
  getFieldsConfiguration,
  getIsPersonalEditModeEnabled,
  getMobileCommunicationTypes,
  getPhoneCommunicationTypes,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { DateManager } from 'utils/DateManager';
import { sortByModeOrder } from 'utils/sorting';
import { sortByTypeOrder } from 'utils/sorting/sortByTypeOrder';

import {
  ProfileDataValidationResult,
  validateProfileData,
} from '../utils/validateProfileData';

import { getRegistrationCardDetails } from './';

export const getProfile = createSelector(
  getRegistrationCardDetails,
  (regCardDetails): KioskProfile | undefined => {
    return regCardDetails?.profile;
  }
);

export const getProfileSecondSurname = createSelector(getProfile, (profile):
  | string
  | undefined => {
  return profile?.personalDetails?.additionalNames?.find(
    (name) => name.type === AdditionalNamesTypes.SecondSurname
  )?.value;
});

export const getSortedProfileAddresses = createSelector(
  getProfile,
  getAddressTypeEntities,
  (profile, addressTypes): KioskAddress[] | undefined => {
    if (!profile?.addresses || !addressTypes) return profile?.addresses;

    const primary = profile.addresses.filter((address) => address.isPrimary);
    const nonPrimary = profile.addresses.filter(
      (address) => !address.isPrimary
    );

    return [
      ...sortByTypeOrder(primary, addressTypes),
      ...sortByTypeOrder(nonPrimary, addressTypes),
    ];
  }
);

export const getSortedProfileEmails = createSelector(
  getProfile,
  getEmailCommunicationTypes,
  (profile, emailTypes): KioskCommunicationChannel[] | undefined => {
    if (!profile?.emails || !emailTypes) return profile?.emails;

    const primary = profile.emails.filter((address) => address.isPrimary);
    const nonPrimary = profile.emails.filter((address) => !address.isPrimary);

    return [
      ...sortByTypeOrder(primary, emailTypes),
      ...sortByTypeOrder(nonPrimary, emailTypes),
    ];
  }
);

export const getSortedProfilePhones = createSelector(
  getProfile,
  getPhoneCommunicationTypes,
  (profile, phoneTypes): KioskCommunicationChannel[] | undefined => {
    if (!profile?.phones || !phoneTypes) return profile?.phones;

    const primary = profile.phones.filter((address) => address.isPrimary);
    const nonPrimary = profile.phones.filter((address) => !address.isPrimary);

    return [
      ...sortByTypeOrder(primary, phoneTypes),
      ...sortByTypeOrder(nonPrimary, phoneTypes),
    ];
  }
);

export const getSortedProfileMobiles = createSelector(
  getProfile,
  getMobileCommunicationTypes,
  (profile, mobileTypes): KioskCommunicationChannel[] | undefined => {
    if (!profile?.mobiles || !mobileTypes) return profile?.mobiles;

    const primary = profile.mobiles.filter((address) => address.isPrimary);
    const nonPrimary = profile.mobiles.filter((address) => !address.isPrimary);

    return [
      ...sortByTypeOrder(primary, mobileTypes),
      ...sortByTypeOrder(nonPrimary, mobileTypes),
    ];
  }
);

export const getSortedProfileCommunicationChannels = createSelector(
  getSortedProfileEmails,
  getSortedProfilePhones,
  getSortedProfileMobiles,
  getCommunicationModeOrder,
  (email, phones, mobiles, modesOrder) => {
    const communicationChannels = [email, phones, mobiles]
      .filter(isDefined)
      .flat();

    const sortedCommunicationChannels = sortByModeOrder(
      communicationChannels,
      modesOrder
    );

    return [
      ...sortedCommunicationChannels.filter((contact) => contact.isPrimary),
      ...sortedCommunicationChannels.filter((contact) => !contact.isPrimary),
    ];
  }
);

export const getValidProfileDocuments = createSelector(
  getProfile,
  getPropertyConfiguration,
  (profile, propertyConfiguration): KioskIdentityDocument[] | undefined => {
    if (!profile?.identityDocuments) return undefined;

    return profile.identityDocuments.filter(
      (document: KioskIdentityDocument): boolean => {
        return document.expiryDate
          ? DateManager.checkIfSameOrAfter(
              document.expiryDate,
              propertyConfiguration?.businessDate
            )
          : true;
      }
    );
  }
);

export const getValidatedProfileData = createSelector(
  getProfile,
  getValidProfileDocuments,
  getFieldsConfiguration,
  getIsPersonalEditModeEnabled,
  (
    profile,
    validDocuments,
    fieldsConfiguration,
    isPersonalEditModeEnabled
  ): ProfileDataValidationResult | undefined => {
    if (!profile || !fieldsConfiguration || !isPersonalEditModeEnabled) {
      return undefined;
    }

    return validateProfileData(profile, validDocuments, fieldsConfiguration);
  }
);

export const getIsProfileDataRequiresCompletion = createSelector(
  getValidatedProfileData,
  (validatedProfileData): boolean => {
    if (!validatedProfileData) {
      return false;
    }

    return Object.values(validatedProfileData).some((value) => !value);
  }
);
