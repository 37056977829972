import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Field, Grid, SelectField } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createRequiredValidator,
  generateFieldValuePath,
} from '@ac/react-infrastructure';

import { KioskTelephoneRegionPrefixes } from 'api/KioskApi/entries';
import {
  getPhoneCommunicationTypes,
  getSuggestedPhonePrefixes,
  getTelephoneRegionPrefixesEntities,
} from 'store/settings/selectors';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import { useSubForm } from 'utils/form/subFormApi';
import {
  FormProperties,
  PhoneFormProperties,
  PhonesFormValues,
} from 'views/RegistrationCardEditPersonal/types';

import { SubFormActionHeader } from '../../../../SubFormActionHeader/SubFormActionHeader';
import { usePhonesFieldsConfiguration } from '../hooks/usePhonesFieldsConfiguration';

import { ContactPhonesFormField } from './PhonesFormFields';

import './SinglePhoneSubForm.scss';

const TEST_SUFFIXES = {
  actionsHeader: '-actions-header',
  type: '-type',
  prefix: '-prefix',
  phoneNumber: '-phone-number',
};

interface Props {
  path: [FormProperties.phones, number];
  isHeaderHidden?: boolean;
  dataTestSelector?: string;
  onRemoveClick?: () => void;
  onSetAsMainClick?: () => void;
}

export const SinglePhoneSubForm = ({
  path,
  isHeaderHidden,
  dataTestSelector,
  onRemoveClick,
  onSetAsMainClick,
}: Props): JSX.Element => {
  const [, fieldsIndex] = path;

  const { t } = useTranslation();
  const subFormState = useSubForm<PhonesFormValues>(path);
  const fieldsConfiguration = usePhonesFieldsConfiguration(path);

  const [isPhonePrefixFieldHidden, setPhonePrefixFieldHidden] = useState(true);

  const phoneTypes = useSelector(getPhoneCommunicationTypes);
  const telephoneRegionPrefixes = useSelector(
    getTelephoneRegionPrefixesEntities
  );
  const suggestedTelephoneRegionPrefixes = useSelector(
    getSuggestedPhonePrefixes
  );

  const phoneExistsAndWasNotChanged =
    subFormState.values?.phoneNumber && !subFormState.dirtyFields?.phoneNumber;

  const phoneOptions = useMemo(
    () => mapSelectOptions(phoneTypes, 'description', 'id'),
    [phoneTypes]
  );

  const suggestedTelephoneRegionPrefixOptions = useMemo(
    () => suggestedTelephoneRegionPrefixes?.map(({ code }) => code),
    [suggestedTelephoneRegionPrefixes]
  );

  const telephoneRegionPrefixOptions = useMemo(() => {
    return telephoneRegionPrefixes
      ?.filter((item) => item.prefix && item.code)
      .map((item: Required<KioskTelephoneRegionPrefixes>) => ({
        title: `${item.prefix} (${item.regionName})`,
        value: item.code,
      }));
  }, [telephoneRegionPrefixes]);

  useEffect(() => {
    setPhonePrefixFieldHidden(
      !(!subFormState.values?.id || subFormState.values?.countryCode)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFormState.values?.id]);

  useEffect(() => {
    if (
      !subFormState.values?.countryCode &&
      subFormState.active === PhoneFormProperties.phoneNumber &&
      !isPhonePrefixFieldHidden
    ) {
      subFormState.api.focusField(PhoneFormProperties.countryCode);
    }
  }, [subFormState, isPhonePrefixFieldHidden]);

  useEffect(() => {
    if (!subFormState.dirty) return;

    const selectedType = phoneTypes?.find(
      (address) => address.id === subFormState.values?.type
    );

    if (isDefined(selectedType) && !selectedType.isActive) {
      subFormState.api.change(PhoneFormProperties.type, undefined);
      subFormState.api.setFieldAsTouched(PhoneFormProperties.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFormState.dirty]);

  return (
    <Box className="single-phone-component" dataTestSelector={dataTestSelector}>
      {!isHeaderHidden && (
        <SubFormActionHeader
          className="spacing-bottom-s"
          title={t(
            'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.PHONE.TITLE_OF_SINGLE',
            {
              count: fieldsIndex + 1,
            }
          )}
          dataTestSelector={dataTestSelector?.concat(
            TEST_SUFFIXES.actionsHeader
          )}
          isMain={subFormState.values?.isPrimary}
          mainControlEnabled
          deleteControlEnabled
          setAsMainButtonDisabled={subFormState.invalid}
          onSetAsMainClick={onSetAsMainClick}
          onRemoveClick={onRemoveClick}
        />
      )}

      <Grid className="gap-lg phone-grid-wrapper">
        {fieldsConfiguration.type?.visible && (
          <Grid.Item className="phone-type">
            <ContactPhonesFormField
              validate={fieldsConfiguration.type?.validator}
              valuePath={fieldsConfiguration.type?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.type
                  )}
                  options={phoneOptions}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.PHONE.FIELD.TYPE'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  allowClear={fieldsConfiguration.type?.optional}
                  optional={fieldsConfiguration.type?.optional}
                  readonly={fieldsConfiguration.type?.readonly}
                />
              )}
            </ContactPhonesFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.phoneNumber?.visible && (
          <>
            {!isPhonePrefixFieldHidden && (
              <Grid.Item className="phone-prefix">
                <ContactPhonesFormField
                  validate={createRequiredValidator(
                    'VALIDATION.FIELD_IS_REQUIRED'
                  )}
                  valuePath={generateFieldValuePath(path)([
                    PhoneFormProperties.countryCode,
                  ])}
                >
                  {(fieldFieldRenderProps): JSX.Element => {
                    const fieldRenderProps = mapFieldRenderProps(
                      fieldFieldRenderProps
                    );

                    let newValue: string;

                    return (
                      <SelectField
                        {...fieldRenderProps}
                        dataTestSelector={dataTestSelector?.concat(
                          TEST_SUFFIXES.prefix
                        )}
                        suggestedOptionValues={
                          suggestedTelephoneRegionPrefixOptions
                        }
                        options={telephoneRegionPrefixOptions}
                        label={t(
                          'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.PHONE.FIELD.PREFIX'
                        )}
                        placeholder={t('SHARED.SELECT')}
                        onChange={(value): void => {
                          newValue = value;
                          fieldRenderProps.onChange(value);

                          if (phoneExistsAndWasNotChanged) {
                            subFormState.api.change(
                              PhoneFormProperties.phoneNumber,
                              undefined
                            );
                            subFormState.api.focusField(
                              PhoneFormProperties.phoneNumber
                            );
                          }
                        }}
                        onBlur={(): void => {
                          const prefixWasNotSelected =
                            !fieldFieldRenderProps.meta.dirty && !newValue;

                          if (
                            phoneExistsAndWasNotChanged &&
                            prefixWasNotSelected
                          ) {
                            setPhonePrefixFieldHidden(true);
                          }

                          fieldRenderProps.onBlur();
                        }}
                      />
                    );
                  }}
                </ContactPhonesFormField>
              </Grid.Item>
            )}

            <Grid.Item className="phone-details">
              <ContactPhonesFormField
                validate={fieldsConfiguration.phoneNumber?.validator}
                valuePath={fieldsConfiguration.phoneNumber?.valuePath}
              >
                {(fieldFieldRenderProps): JSX.Element => {
                  const fieldRenderProps = mapFieldRenderProps(
                    fieldFieldRenderProps
                  );

                  return (
                    <Field
                      {...fieldRenderProps}
                      dataTestSelector={dataTestSelector?.concat(
                        TEST_SUFFIXES.phoneNumber
                      )}
                      label={t(
                        'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.PHONE.FIELD.PHONE_NUMBER'
                      )}
                      placeholder={t('SHARED.FILL')}
                      onFocus={(): void => {
                        if (isPhonePrefixFieldHidden) {
                          setPhonePrefixFieldHidden(false);
                        }

                        fieldRenderProps.onFocus();
                      }}
                      optional={fieldsConfiguration.phoneNumber?.optional}
                      readonly={fieldsConfiguration.phoneNumber?.readonly}
                    />
                  );
                }}
              </ContactPhonesFormField>
            </Grid.Item>
          </>
        )}
      </Grid>
    </Box>
  );
};

SinglePhoneSubForm.testSuffixes = TEST_SUFFIXES;
