import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getFieldsConfiguration } from 'store/settings/selectors';
import {
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';

import { useAddressesInitialValues } from './useAddressesInitialValues';
import { useDocumentsInitialValues } from './useDocumentsInitialValues';
import { useEmailsInitialValues } from './useEmailsInitialValues';
import { useMobilesInitialValues } from './useMobilesInitialValues';
import { usePersonalDetailsInitialValues } from './usePersonalDetailsInitialValues';
import { usePhonesInitialValues } from './usePhonesInitialValues';

export const useFormInitialValues = (): FormValues => {
  const fieldsConfiguration = useSelector(getFieldsConfiguration);

  const {
    initialValues: personalDetailsInitialValues,
  } = usePersonalDetailsInitialValues();
  const { initialValues: addressInitialValues } = useAddressesInitialValues();
  const { initialValues: emailsInitialValues } = useEmailsInitialValues();
  const { initialValues: phonesInitialValues } = usePhonesInitialValues();
  const { initialValues: mobilesInitialValues } = useMobilesInitialValues();
  const { initialValues: documentsInitialValues } = useDocumentsInitialValues();

  const isPersonalDetailsSectionEditable =
    fieldsConfiguration?.guestPersonalDetailsObject?.isEditable;
  const isAddressesSectionEditable =
    fieldsConfiguration?.addressesObjectCollection?.isEditable;
  const isEmailsSectionEditable =
    fieldsConfiguration?.emailObjectCollection?.isEditable;
  const isMobilesSectionEditable =
    fieldsConfiguration?.mobileObjectCollection?.isEditable;
  const isPhonesSectionEditable =
    fieldsConfiguration?.phoneObjectCollection?.isEditable;
  const isDocumentSectionEditable =
    fieldsConfiguration?.identityDocumentObjectCollection?.isEditable;

  return useMemo(
    () => ({
      ...(isPersonalDetailsSectionEditable
        ? { [FormProperties.personalDetails]: personalDetailsInitialValues }
        : undefined),

      ...(isAddressesSectionEditable
        ? { [FormProperties.addresses]: addressInitialValues }
        : undefined),

      ...(isEmailsSectionEditable
        ? { [FormProperties.emails]: emailsInitialValues }
        : undefined),

      ...(isMobilesSectionEditable
        ? { [FormProperties.mobiles]: mobilesInitialValues }
        : undefined),

      ...(isPhonesSectionEditable
        ? { [FormProperties.phones]: phonesInitialValues }
        : undefined),

      ...(isDocumentSectionEditable
        ? { [FormProperties.documents]: documentsInitialValues }
        : undefined),
    }),
    [
      addressInitialValues,
      documentsInitialValues,
      emailsInitialValues,
      isAddressesSectionEditable,
      isDocumentSectionEditable,
      isEmailsSectionEditable,
      isMobilesSectionEditable,
      isPersonalDetailsSectionEditable,
      isPhonesSectionEditable,
      mobilesInitialValues,
      personalDetailsInitialValues,
      phonesInitialValues,
    ]
  );
};
