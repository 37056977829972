import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  composeValidators,
  createRequiredValidator,
  FieldValidator,
  ValidationExpression,
} from '@ac/react-infrastructure';

import {
  FieldConfiguration,
  SectionConfiguration,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import { getFieldsConfiguration } from 'store/settings/selectors';
import { useSubForm } from 'utils/form/subFormApi';

import { BaseObject } from 'types/shared';

export interface FormFieldConfiguration<FormValues, ValuePathType, FieldValue> {
  visible: boolean;
  readonly: boolean;
  optional: boolean;
  valuePath: ValuePathType;
  validator?: FieldValidator<FormValues, FieldValue>;
}

export type SubFormFieldsConfiguration<
  FormValues,
  SubForValues,
  ValuePathType extends Array<string | number>
> = {
  [Key in keyof SubForValues]: FormFieldConfiguration<
    FormValues,
    [...ValuePathType, Key],
    SubForValues[Key]
  >;
};

export type DefaltValidationRules<FormValues, SubForValues> = {
  [Key in keyof SubForValues]?: Array<
    ValidationExpression<FormValues, SubForValues[Key]>
  >;
};

export type ConfigurationFieldsMap<
  ConfigurationKeys extends string,
  SubForValues
> = Partial<Record<ConfigurationKeys, keyof SubForValues>>;

export type SubFormFieldsConfigurationResult<
  FormValues,
  SubForValues,
  ValuePathType extends Array<string | number>
> = (
  configurationFieldsMap: ConfigurationFieldsMap<string, SubForValues>,
  defaultValidationRules?: DefaltValidationRules<FormValues, SubForValues>
) => SubFormFieldsConfiguration<FormValues, SubForValues, ValuePathType>;

export const useSubFormFieldsConfiguration = <
  FormValues,
  SubForValues extends BaseObject,
  ValuePathType extends Array<string | number>
>(
  configurationKey: keyof SectionConfiguration,
  path: ValuePathType
): SubFormFieldsConfigurationResult<
  FormValues,
  SubForValues,
  ValuePathType
> => {
  const formApi = useSubForm<SubForValues>(path, { initialValues: true });
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const configuration = fieldsConfiguration?.[configurationKey];

  return useCallback(
    (configurationFieldsMap, defaultValidationRules = {}) => {
      const config = {} as SubFormFieldsConfiguration<
        FormValues,
        SubForValues,
        ValuePathType
      >;

      if (!configuration) return config;
      const configEntires = Object.entries(configuration);

      return configEntires.reduce(
        (acc, [key, value]: [string, FieldConfiguration | undefined]) => {
          const formFieldKey: keyof SubForValues | undefined =
            configurationFieldsMap[key];

          if (!formFieldKey) return acc;
          const initialValue = formApi.initialValues?.[formFieldKey];

          const defaultValidators = defaultValidationRules[formFieldKey] ?? [];
          const additionalValidators = value?.isRequired
            ? [createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED')]
            : [];

          const isReadonlyWithInitialValue =
            !value?.isEditable && !!value?.isVisible && !!initialValue;

          acc[formFieldKey] = {
            visible: value?.isEditable || isReadonlyWithInitialValue,
            readonly: !value?.isEditable,
            optional: !!value?.isEditable && !value?.isRequired,
            valuePath: [...path, formFieldKey],
            validator: value?.isEditable
              ? composeValidators(...additionalValidators, ...defaultValidators)
              : undefined,
          };

          return acc;
        },
        config
      );
    },
    [configuration, formApi, path]
  );
};
