import { Reducer } from 'react';
import { Action, CombinedState, combineReducers } from 'redux';

/**
 * global stores
 */
import { additionalDictionaries } from 'store/additionalDictionaries/reducer';
import { app } from 'store/app/reducer';
import { electronicRegistrationProcess } from 'store/electronicRegistrationProcess/reducer';
import { settings } from 'store/settings/reducer';
/**
 * View's stores
 */
import { loginView } from 'views/Login/store/reducer';
import { registrationCardEditComplementaryView } from 'views/RegistrationCardEditComplementary/store/reducer';
import { registrationCardEditPersonalView } from 'views/RegistrationCardEditPersonal/store/reducer';
import { registrationCardPurchaseElementsView } from 'views/RegistrationCardPurchaseElements/store/reducer';

import { BaseObject } from 'types/shared';

import { Store } from './types';

export const createRootReducer = (
  additionalReducers?: BaseObject<Reducer<unknown, unknown>>
): Reducer<CombinedState<Store>, Action<string>> =>
  combineReducers<Store>({
    /**
     * global stores
     */
    app,
    electronicRegistrationProcess,
    settings,
    additionalDictionaries,
    /**
     * View's stores
     */
    loginView,
    registrationCardEditComplementaryView,
    registrationCardPurchaseElementsView,
    registrationCardEditPersonalView,

    ...additionalReducers,
  });
