import { PhoneSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import {
  ConfigurationFieldsMap,
  SubFormFieldsConfiguration,
  useSubFormFieldsConfiguration,
} from 'views/RegistrationCardEditPersonal/hooks/useSubFormFieldsConfiguration';

import {
  FormProperties,
  FormValues,
  PhoneFormProperties,
  PhonesFormValues,
} from '../../../../../types';

const CONFIGURATION_TO_FORM_FIELD_MAP: ConfigurationFieldsMap<
  keyof PhoneSection,
  PhonesFormValues
> = {
  detailsField: PhoneFormProperties.phoneNumber,
  typeField: PhoneFormProperties.type,
};

type PhonesFieldsConfigurationResult = SubFormFieldsConfiguration<
  FormValues,
  PhonesFormValues,
  [FormProperties.phones, number]
>;

export const usePhonesFieldsConfiguration = (
  path: [FormProperties.phones, number]
): PhonesFieldsConfigurationResult => {
  const getPhonesSubFormConfiguration = useSubFormFieldsConfiguration<
    FormValues,
    PhonesFormValues,
    [FormProperties.phones, number]
  >('phoneObjectCollection', path);

  return getPhonesSubFormConfiguration(CONFIGURATION_TO_FORM_FIELD_MAP);
};
