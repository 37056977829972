import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { TimePickerField } from '@ac/kiosk-components';
import {
  createRequiredValidator,
  FieldRenderProps,
  formFieldFactory,
} from '@ac/react-infrastructure';

import { getReservation } from 'store/electronicRegistrationProcess/selectors/reservation';
import {
  getDateTimeFormats,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { mapFieldRenderProps } from 'utils/form';

import { BaseObject } from 'types/shared';

import './Editable.scss';

const FormTimeField = formFieldFactory<Pick<BaseObject, string>>();

interface EtdFieldProps {
  valuePath: string;
  dataTestSelector?: string;
  className?: string;
}

export const Editable = ({
  valuePath,
  dataTestSelector,
  className,
}: EtdFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const reservation = useSelector(getReservation);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const fieldConfiguration = useSelector(getFieldsConfiguration);

  const isDisabled = reservation?.etaEtdGuaranteed;

  const isEtdRequired =
    !isDisabled &&
    fieldConfiguration?.reservationDetailsObject?.estimatedTimeOfDepartureField
      ?.isRequired;

  return (
    <FormTimeField
      valuePath={valuePath}
      validate={
        isEtdRequired
          ? createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED')
          : undefined
      }
    >
      {(fieldRenderProps: FieldRenderProps<string>): JSX.Element => (
        <TimePickerField
          {...mapFieldRenderProps(fieldRenderProps)}
          className={classNames('reg-card-etd-edit', className)}
          dataTestSelector={dataTestSelector}
          label={t('COMPONENTS.ADDITIONAL_DETAILS_SECTION.ETD')}
          placeholder={t('SHARED.SELECT')}
          disabled={isDisabled}
          fieldDisplayFormat={dateTimeFormats?.timeFormat}
        />
      )}
    </FormTimeField>
  );
};
