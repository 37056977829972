import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  DATE_PICKER_DATE_FORMAT,
  DatePickerField,
  Field,
  Grid,
  IconTypes,
  SelectField,
} from '@ac/kiosk-components';

import {
  getDateTimeFormats,
  getFieldsConfiguration,
  getNationalitiesEntities,
  getProfileSuffixesEntities,
  getPropertyConfiguration,
  getTitlesEntities,
} from 'store/settings/selectors';
import { DateManager } from 'utils';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import { useSubForm } from 'utils/form/subFormApi';

import {
  FormProperties,
  PersonalDetailsFormProperties,
  PersonalDetailsFormValues,
} from '../../types';
import { SubFormSectionWrapper } from '../SubFormSectionWrapper/SubFormSectionWrapper';

import { AlternateDetailsSubForm } from './components/AlternateDetailsSubForm';
import { PersonalDetailsFormField } from './components/PersonalDetailsFormField';
import { usePersonalDetailsFieldsConfiguration } from './hooks/usePersonalDetailsFieldsConfiguration';

const TEST_SUFFIXES = {
  personalTitle: '-person-title',
  lastName: '-last-name',
  surname: '-surname',
  firstName: '-first-name',
  middleName: '-middle-name',
  suffix: '-suffix',
  dateOfBirth: '-date-of-birth',
  nationality: '-nationality',
  addAlternateDetailsButton: '-add-alternate-details-button',
  alternateDetailsSubForm: '-alternate-details-sub-form',
};

interface Props {
  dataTestSelector?: string;
  isExpanded?: boolean;
}

export const PersonalDetailsSubForm = ({
  dataTestSelector,
  isExpanded,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const fieldsConfiguration = usePersonalDetailsFieldsConfiguration();
  const subForm = useSubForm<PersonalDetailsFormValues>([
    FormProperties.personalDetails,
  ]);

  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const sectionsConfiguration = useSelector(getFieldsConfiguration);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const nationalities = useSelector(getNationalitiesEntities);
  const titles = useSelector(getTitlesEntities);
  const profileSuffixes = useSelector(getProfileSuffixesEntities);

  const {
    isEditable: isAlternateDetailsEditable,
    isRequired: isAlternateDetailsRequired,
  } = sectionsConfiguration?.guestAlternateNameObject || {};

  const hasProvidedAlternateDetails = !!subForm.values?.alternateDetails;

  const nationalitiesOptions = useMemo(() => {
    return mapSelectOptions(nationalities, 'name', 'code');
  }, [nationalities]);

  const titlesOptions = useMemo(() => {
    return mapSelectOptions(titles, 'description', 'id');
  }, [titles]);

  const suffixesOptions = useMemo(() => {
    return mapSelectOptions(profileSuffixes, 'description', 'id');
  }, [profileSuffixes]);

  const handleAddAlternateDetails = useCallback(() => {
    return subForm.api.change(
      PersonalDetailsFormProperties.alternateDetails,
      {}
    );
  }, [subForm]);

  const handleRemoveAlternateDetails = useCallback(() => {
    return subForm.api.change(
      PersonalDetailsFormProperties.alternateDetails,
      undefined
    );
  }, [subForm]);

  return (
    <SubFormSectionWrapper
      dataTestSelector={dataTestSelector}
      isValid={subForm.valid}
      title={t('REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.TITLE')}
      isExpanded={isExpanded}
    >
      <Grid gridTemplateColumnsSm="repeat(12, 1fr)" className="gap-lg">
        {fieldsConfiguration.title?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.title?.validator}
              valuePath={fieldsConfiguration.title?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.personalTitle
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.TITLE'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  options={titlesOptions}
                  allowClear={fieldsConfiguration.title?.optional}
                  optional={fieldsConfiguration.title?.optional}
                  readonly={fieldsConfiguration.title?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.lastName?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.lastName?.validator}
              valuePath={fieldsConfiguration.lastName?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.lastName
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.LAST_NAME'
                  )}
                  placeholder={t('SHARED.FILL')}
                  optional={fieldsConfiguration.lastName?.optional}
                  readonly={fieldsConfiguration.lastName?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.secondSurname?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.secondSurname?.validator}
              valuePath={fieldsConfiguration.secondSurname?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.surname
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.SECOND_SURNAME'
                  )}
                  placeholder={t('SHARED.FILL')}
                  optional={fieldsConfiguration.secondSurname?.optional}
                  readonly={fieldsConfiguration.secondSurname?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.firstName?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.firstName?.validator}
              valuePath={fieldsConfiguration.firstName?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.firstName
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.FIRST_NAME'
                  )}
                  placeholder={t('SHARED.FILL')}
                  optional={fieldsConfiguration.firstName?.optional}
                  readonly={fieldsConfiguration.firstName?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.middleName?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.middleName?.validator}
              valuePath={fieldsConfiguration.middleName?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.middleName
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.MIDDLE_NAME'
                  )}
                  placeholder={t('SHARED.FILL')}
                  optional={fieldsConfiguration.middleName?.optional}
                  readonly={fieldsConfiguration.middleName?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.suffix?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.suffix.validator}
              valuePath={fieldsConfiguration.suffix.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.suffix
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.SUFFIX'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  options={suffixesOptions}
                  allowClear={fieldsConfiguration.suffix?.optional}
                  optional={fieldsConfiguration.suffix?.optional}
                  readonly={fieldsConfiguration.suffix?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.birthday?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.birthday?.validator}
              valuePath={fieldsConfiguration.birthday?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <DatePickerField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.dateOfBirth
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.DATE_OF_BIRTH'
                  )}
                  placeholder={dateTimeFormats?.shortDateFormat}
                  fieldDisplayFormat={dateTimeFormats?.shortDateFormat}
                  defaultPickerValue={DateManager.getFormattedDate(
                    propertyConfiguration?.businessDate,
                    DATE_PICKER_DATE_FORMAT
                  )}
                  allowClear={fieldsConfiguration.birthday?.optional}
                  optional={fieldsConfiguration.birthday?.optional}
                  readonly={fieldsConfiguration.birthday?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}

        {fieldsConfiguration.nationality?.visible && (
          <Grid.Item colSpanSm={4}>
            <PersonalDetailsFormField
              validate={fieldsConfiguration.nationality?.validator}
              valuePath={fieldsConfiguration.nationality?.valuePath}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <SelectField
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector={dataTestSelector?.concat(
                    TEST_SUFFIXES.nationality
                  )}
                  label={t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.FIELD.NATIONALITY'
                  )}
                  placeholder={t('SHARED.SELECT')}
                  options={nationalitiesOptions}
                  allowClear={fieldsConfiguration.nationality?.optional}
                  optional={fieldsConfiguration.nationality?.optional}
                  readonly={fieldsConfiguration.nationality?.readonly}
                />
              )}
            </PersonalDetailsFormField>
          </Grid.Item>
        )}
      </Grid>

      {isAlternateDetailsEditable && (
        <>
          {isAlternateDetailsRequired || hasProvidedAlternateDetails ? (
            <AlternateDetailsSubForm
              dataTestSelector={dataTestSelector?.concat(
                TEST_SUFFIXES.alternateDetailsSubForm
              )}
              isRemoveControlPossible={!isAlternateDetailsRequired}
              onRemoveClick={handleRemoveAlternateDetails}
            />
          ) : (
            <Button
              icon={IconTypes.plus}
              pattern={ButtonPattern.tertiary}
              className="spacing-top-xs"
              dataTestSelector={dataTestSelector?.concat(
                TEST_SUFFIXES.addAlternateDetailsButton
              )}
              onClick={handleAddAlternateDetails}
            >
              {t(
                'REGISTRATION_CARD_EDIT_PERSONAL.PERSONAL.ALT_DETAILS.ADD_NEW_BUTTON'
              )}
            </Button>
          )}
        </>
      )}
    </SubFormSectionWrapper>
  );
};

PersonalDetailsSubForm.testSuffixes = TEST_SUFFIXES;
