import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import { getSortedProfileEmails } from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getDefaultEmailType,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import {
  EmailFormProperties,
  EmailsFormValues,
} from 'views/RegistrationCardEditPersonal/types';

interface EmailsInitialValuesResult {
  prepareInitialEmptyEmail: (isPrimary: boolean) => EmailsFormValues;
  initialValues: EmailsFormValues[] | undefined;
}

export const useEmailsInitialValues = (): EmailsInitialValuesResult => {
  const emails = useSelector(getSortedProfileEmails);
  const defaultEmailType = useSelector(getDefaultEmailType);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const emailsConfiguration = fieldsConfiguration?.emailObjectCollection;

  const prepareInitialEmptyEmail = useCallback(
    (isPrimary: boolean): EmailsFormValues => {
      return {
        [EmailFormProperties.isPrimary]: isPrimary,
        [EmailFormProperties.type]: defaultEmailType?.id,
      };
    },
    [defaultEmailType]
  );

  const prepareSinglePhoneInitialValues = useCallback(
    (
      email?: Partial<KioskCommunicationChannel>
    ): EmailsFormValues | undefined => {
      return {
        [EmailFormProperties.id]: email?.id,
        [EmailFormProperties.isPrimary]: email?.isPrimary || false,
        [EmailFormProperties.details]: email?.details,
        [EmailFormProperties.type]: email?.type?.id,
      } as EmailsFormValues;
    },
    []
  );

  const initialValues = useMemo(() => {
    if (!emails?.length && emailsConfiguration?.isRequired) {
      return [prepareInitialEmptyEmail(true)];
    }

    return emails?.map(prepareSinglePhoneInitialValues).filter(isDefined);
  }, [
    emails,
    emailsConfiguration,
    prepareSinglePhoneInitialValues,
    prepareInitialEmptyEmail,
  ]);

  return {
    prepareInitialEmptyEmail,
    initialValues,
  };
};
