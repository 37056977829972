import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getSortedProfileCommunicationChannels,
  getValidatedProfileData,
} from 'store/electronicRegistrationProcess/selectors/profile';
import { getFieldsConfiguration } from 'store/settings/selectors';
import { mapContactsData } from 'utils/regCardPresentationDataMappers/mapContactsData';

import { ProfilePresentationSectionData } from '../types';

interface Props {
  showOnlyPrimary?: boolean;
}

export const useContactsConfiguration = ({
  showOnlyPrimary,
}: Props): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const communicationChannels = useSelector(
    getSortedProfileCommunicationChannels
  );

  const contactsData = useMemo(
    () =>
      mapContactsData(communicationChannels, {
        showOnlyPrimary,
        emailFieldsConfiguration: fieldsConfiguration?.emailObjectCollection,
        mobileFieldsConfiguration: fieldsConfiguration?.mobileObjectCollection,
        phoneFieldsConfiguration: fieldsConfiguration?.phoneObjectCollection,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [communicationChannels, fieldsConfiguration, showOnlyPrimary, i18n.language]
  );

  const contactsAreValid = useMemo(() => {
    return validatedProfileData
      ? validatedProfileData.emails &&
          validatedProfileData.mobiles &&
          validatedProfileData.phones
      : true;
  }, [validatedProfileData]);

  const isContactSectionVisible = useMemo(() => {
    return (
      (fieldsConfiguration?.emailObjectCollection?.isVisible ||
        fieldsConfiguration?.phoneObjectCollection?.isVisible ||
        fieldsConfiguration?.mobileObjectCollection?.isVisible) ??
      true
    );
  }, [fieldsConfiguration]);

  return useMemo(
    () => ({
      data: contactsData,
      isVisible: isContactSectionVisible,
      isMissingRequiredInformation: !contactsAreValid,
    }),
    [contactsData, isContactSectionVisible, contactsAreValid]
  );
};
